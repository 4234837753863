module Laserlog.State

open Elmish
open Types
open System
open Thoth.Json
open Thoth.Elmish
open Fable.SimpleHttp

let init () : Model * Cmd<Msg> =
    { FromDate = DateTime.Now.AddDays(-20.)
      ToDate = DateTime.Now
      Laserlog = []
      ErrorMsg = ""
      SearchMode = Top20
      ShowGraph = false
      PowerLog = []
      WindowSize = 15 },
    Cmd.none

// API calls
let refreshList successHandler errorHandler =
    async {
        let! result = API.getTopLaserlog

        match result with
        | Ok data -> return successHandler data
        | Error e -> return errorHandler e
    }
    |> Cmd.OfAsync.result

let refreshListFromTo (fromDate: DateTime, toDate: DateTime) successHandler errorHandler =
    async {
        let! result = API.getLaserlogFromTo (fromDate, toDate)

        match result with
        | Ok data -> return successHandler data
        | Error e -> return errorHandler e
    }
    |> Cmd.OfAsync.result

let getLaserPowerLog logentry successHandler errorHandler =
    async {
        let! result = API.getLaserPowerLog logentry

        match result with
        | [] -> return errorHandler "No data for this laserweld"
        | data -> return successHandler data
    }
    |> Cmd.OfAsync.result


// Handle app messages
let update msg model =
    match msg with
    // Initialize/Refresh data
    | Initialize -> model, refreshList OnRefreshed OnError
    | Refresh -> model, refreshList OnRefreshed OnError
    //model, refreshList
    | OnRefreshed data -> { model with Laserlog = data }, Cmd.none
    | OnListRefreshedError e -> { model with Laserlog = [] }, Cmd.none
    | OnError s -> { model with ErrorMsg = s }, Cmd.none

    // User input
    | SearchModeChanged m ->
        match m with
        | Top20 ->
            { model with
                  SearchMode = m
                  Laserlog = [] },
            refreshList OnRefreshed OnListRefreshedError
        | DateRange ->
            { model with
                  SearchMode = m
                  Laserlog = [] },
            refreshListFromTo (model.FromDate, model.ToDate) OnRefreshed OnListRefreshedError
    | FromDateChanged s ->
        let isOk, date = DateTime.TryParse s

        if isOk then
            { model with
                  FromDate = date
                  Laserlog = [] },
            refreshListFromTo (date, model.ToDate) OnRefreshed OnListRefreshedError
        else
            model, Cmd.none
    | ToDateChanged s ->
        let isOk, date = DateTime.TryParse s

        if isOk then
            { model with
                  ToDate = date
                  Laserlog = [] },
            refreshListFromTo (model.FromDate, date) OnRefreshed OnError
        else
            model, Cmd.none
    | WindowSizeChanged s ->
        let (ok, value) = Int32.TryParse s

        let limitSize i =
            if i < 1 then 1
            elif i > 100 then 100
            else i

        match ok with
        | true ->
            { model with
                  WindowSize = value |> limitSize },
            Cmd.none
        | false -> model, Cmd.none

    | ShowGraph logentry ->
        match logentry with
        | Some e -> { model with ShowGraph = true }, getLaserPowerLog e OnPowerLogLoaded OnError
        | None ->
            { model with
                  ShowGraph = false
                  PowerLog = [] },
            Cmd.none
    | OnPowerLogLoaded data -> { model with PowerLog = data }, Cmd.none
