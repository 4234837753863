module Lasersveis.View

open Fable.Core
open Fable.React
open Fable.React.Props
open Feliz
open Feliz.Recharts
open Feliz.Bulma
open Types
open System
open System.Globalization


let msToS v = v/1000

let showLasersveisTableRow dispatch isChanged (ls:Lasersveis) =
    Html.tableRow[
        let backgroundColor = 
            if isChanged then
                [ (style.backgroundColor "#FFFABC")]
            else
                [ (style.backgroundColor "#fff")]
        
        prop.style backgroundColor
        prop.children[
            Html.td[
                prop.style backgroundColor
                prop.children[
                    str (ls.Id.ToString())
                ]
            ]
            Html.td (ls.DateWelded.ToString("yyyy-MM-dd HH:mm:ss", CultureInfo.InvariantCulture))
            Html.td (ls.Duration |> msToS |> sprintf "%is")
            Html.td (ls.LightPath.ToString())
            Html.td (ls.LaserProgram.ToString())
            Html.td (ls.LaserPower.ToString())
            Html.td (ls.PlasmoProgram.ToString())
            Html.td (ls.PlasmoOk.ToString())
            Html.td[
                prop.children[
                    let classification = ls |> Lasersveis.Classification
                    Bulma.label [
                        Bulma.text.span "OK:"
                        Bulma.input.checkbox[
                            prop.isChecked (if classification = OK then true else false)
                            prop.onCheckedChange (fun b -> if b = true then dispatch (OnClassificationChanged (ls.Id, OK)) )
                        ]
                        Bulma.text.span "NOK:"
                        Bulma.input.checkbox[
                            prop.isChecked (if classification = NOK then true else false)
                            prop.onCheckedChange (fun b -> if b = true then dispatch (OnClassificationChanged (ls.Id, NOK)) )
                        ]
                        Bulma.text.span "NE:"
                        Bulma.input.checkbox[
                            prop.isChecked (if classification = NE then true else false)
                            prop.onCheckedChange (fun b -> if b = true then dispatch (OnClassificationChanged (ls.Id, NE) ))
                        ]
                    ]
                ]
            ]
            Html.td[
                Html.input[
                    prop.type'.text
                    prop.defaultValue (if ls.ManualClassificationDescription.IsSome then ls.ManualClassificationDescription.Value else "")
                    prop.onTextChange (fun s -> dispatch (OnClassificaitonDescriptionChanged (ls.Id, s) ))
                ]
                if isChanged then
                    Bulma.button.button[
                        prop.style backgroundColor
                        prop.onClick (fun r -> dispatch (ClassificationUpdate ls.Id))
                        Bulma.button.isSmall
                        prop.children[
                            Bulma.icon[
                                Bulma.icon.isLarge
                                prop.children[
                                    Html.i [prop.className "fas fa-save"]
                                ]
                            ]
                        ]
                    ]
                else
                    Html.none   
            ]
            //Html.td[
            //]
        ]
    ]

let showLasersveisTable dispatch (lasersveiseList:Lasersveis list) (unchangedList:Lasersveis list) =
    Html.div[
        prop.className "table-container"
        prop.children [
            Html.table[
                prop.className "table is-bordered is-fullwidth"
                prop.children[
                    Html.thead[
                        Html.tr[
                            Html.th[
                                prop.colSpan 3
                                prop.text "Info"
                            ]
                            Html.th[
                                prop.colSpan 3
                                prop.text "Laser"
                            ]
                            Html.th[
                                prop.colSpan 2
                                prop.text "Plasmo"
                            ]
                            Html.th[
                                prop.colSpan 2
                                prop.text "Manual Input"
                            ]
                        ]
                        Html.tr[
                            Html.th "Id"
                            Html.th "DateWelded"
                            Html.th "Length"
                            Html.th "LP"
                            Html.th "Prg"
                            Html.th "Pwr"
                            Html.th "Prg"
                            Html.th "Ok"
                            Html.th "OK/NOK/NE"
                            Html.th "Description"
                        ]
                    ]
                    Html.tbody[
                        let list = (lasersveiseList, unchangedList) ||> List.map2 (fun a b -> (a,b))
                        for sveis, unchangedSveis in list do
                            let isChanged = if sveis = unchangedSveis then false else true
                            showLasersveisTableRow dispatch isChanged sveis
                    ]
                ]
            ]
        ]
    ]

let showDateRangePicker model dispatch =
    div [ClassName "columns"; Style [Padding "1.5rem"; FontWeight "700"]] [
        div [ClassName "column is-offset-one-quarter"] [
            form [ClassName "box"] [
                div [ClassName "Field"] [
                    label [Style [Display DisplayOptions.Block]] [str "From Date"]
                    div [ClassName "control"] [
                        Html.input [
                            prop.className "input"
                            prop.style [style.borderWidth 1; style.borderStyle.solid; style.borderColor "#dbdbdb" ]
                            prop.type' "date"
                            prop.value (model.FromDate.ToString("yyyy-MM-dd"))
                            prop.placeholder (DateTime.Now.AddDays(-20.).ToString("dd-MM-yyyy"))
                            prop.onChange (fun v -> dispatch (FromDateChanged v))
                        ]
                    ]
                ]
                div [ClassName "Field"] [
                    label [] [str "To Date"]
                    div [ClassName "control"] [
                        Html.input [
                            prop.className "input"
                            prop.style [style.borderWidth 1; style.borderStyle.solid; style.borderColor "#dbdbdb" ]
                            prop.type' "date"
                            prop.value (model.ToDate.ToString("yyyy-MM-dd"))
                            prop.placeholder (sprintf "%s" (DateTime.Now.ToString("dd-MM-yyyy")))
                            prop.onChange (fun v -> dispatch (ToDateChanged v))
                        ]
                    ]
                ]
            ]
        ]
    ]

let root model dispatch =
    Html.div[
        prop.children [
            Html.div[
                prop.className "columns is-3 is-variable"
                prop.children[
                    Html.div[
                        prop.className "control"
                        prop.children[
                            label [Style [Display DisplayOptions.Block]] [str "Search Mode:"]
                            match model.SearchMode with
                            | DateRange ->
                                Bulma.button.button[
                                    Bulma.color.isLight
                                    prop.text "Top 20"
                                    prop.onClick (fun e -> SearchModeChanged Top20 |> dispatch)
                                ]
                                Bulma.button.button[
                                    prop.style [style.backgroundColor "#3273dc"; style.color "#fff"]
                                    prop.text "DateRange"
                                ]
                            | Top20 ->
                                Bulma.button.button[
                                    prop.style [style.backgroundColor "#3273dc"; style.color "#fff"]
                                    prop.text "Top 20"
                                ]
                                Bulma.button.button[
                                    Bulma.color.isLight
                                    prop.text "DateRange"
                                    prop.onClick (fun e -> SearchModeChanged DateRange |> dispatch)
                            ]
                        ]
                    ]
                ]
            ]
            if model.SearchMode = DateRange then
                Html.div[
                    prop.className "columns"
                    prop.children [
                        showDateRangePicker model dispatch
                    ]
                ]
            else
                Html.none

            Html.br[]
            Html.div[
                prop.className "columns"
                prop.children [
                    showLasersveisTable dispatch model.Lasersveis model.UnchangedLasersveis
                ]
            ]
        ]
    ]
