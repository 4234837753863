module OrdreCustomers.Types

open System
open Thoth.Json

type Prodtex = AS | Industri

type OrdreCustomer = 
    { KundeNr: int
      Kundenavn: string option
      Adresse1: string option
      Adresse2: string option
      Adresse3: string option
      Adresse4: string option
      Kontaktperson: string option
      KontoNr: string option
      Telefon: string option
      Telefax: string option
      Mobil: string option
      Rabattsats: float option
      Betalingsbetingelse: float option
      Leveringsbetingelse: string option
      Språk: string option
      Tekst:  string option
      Gruppe: float option
      Web: string option
      EMail: string option
      Mva: string option
      Iban: string option
      Swift: string option
      Land: int option
      Zip: string option
      SendType: int option
      SsmaTimeStamp: string
      VismaId: int option }

    static member Decoder =
        Decode.object (fun get ->
            { KundeNr = get.Required.Field "KundeNr" Decode.int
              Kundenavn = get.Optional.Field "Kundenavn" Decode.string
              Adresse1 = get.Optional.Field "Adresse1" Decode.string
              Adresse2 = get.Optional.Field "Adresse2" Decode.string
              Adresse3 = get.Optional.Field "Adresse3" Decode.string
              Adresse4 = get.Optional.Field "Adresse4" Decode.string
              Kontaktperson = get.Optional.Field "Kontaktperson" Decode.string
              KontoNr = get.Optional.Field "KontoNr" Decode.string
              Telefon = get.Optional.Field "Telefon" Decode.string
              Telefax = get.Optional.Field "Telefax" Decode.string
              Mobil = get.Optional.Field "Mobil" Decode.string
              Rabattsats = get.Optional.Field "Rabattsats" Decode.float
              Betalingsbetingelse = get.Optional.Field "Betalingsbetingelse" Decode.float
              Leveringsbetingelse = get.Optional.Field "Leveringsbetingelse" Decode.string
              Språk = get.Optional.Field "Språk" Decode.string
              Tekst = get.Optional.Field "Tekst" Decode.string
              Gruppe = get.Optional.Field "Gruppe" Decode.float
              Web = get.Optional.Field "Web" Decode.string
              EMail = get.Optional.Field "EMail" Decode.string
              Mva = get.Optional.Field "Mva" Decode.string
              Iban = get.Optional.Field "Iban" Decode.string
              Swift = get.Optional.Field "Swift" Decode.string
              Land = get.Optional.Field "Land" Decode.int
              Zip = get.Optional.Field "Zip" Decode.string
              SendType = get.Optional.Field "SendType" Decode.int
              SsmaTimeStamp = get.Required.Field "SsmaTimeStamp" Decode.string
              VismaId = get.Optional.Field "VismaId" Decode.int })
    static member Encode(cust: OrdreCustomer) = Encode.Auto.toString (0, cust)

    static member New() =
        {
          KundeNr = 0
          Kundenavn = None
          Adresse1 = None
          Adresse2 = None
          Adresse3 = None
          Adresse4 = None
          Kontaktperson = None
          KontoNr = None
          Telefon = None
          Telefax = None
          Mobil = None
          Rabattsats = None
          Betalingsbetingelse = None
          Leveringsbetingelse = None
          Språk = None
          Tekst = None
          Gruppe = None
          Web = None
          EMail = None
          Mva = None
          Iban = None
          Swift = None
          Land = None
          Zip = None
          SendType = None
          SsmaTimeStamp = "AAAAAAAA+JM=" 
          VismaId = None
          }

type Model =
    { Customers: OrdreCustomer list
      NewCustomer: OrdreCustomer
      NextCustomerNumber: int
      Errors: string }

type Msg =
    | Init
    | CustomersFetched of Result<OrdreCustomer list, string>
    | OnError of string
    | Submit
    | OnCustCreated of Result<OrdreCustomer, string>
    | UpdateNewCustomer of (OrdreCustomer -> OrdreCustomer)
