module OrdreProjects.View

open Helpers
open Elmish
open System
open Fable.React
open Feliz
open Feliz.Bulma
open OrdreProjects.Types
open OrdreProjects.State
open Common
open Elements
open Fable.Import.RemoteDev

/// Customer numbers in prodtex AS is starting from 12000,
/// we want to trim those down
let trimCustNumber (n:int) : int =
    if ( n < 12000) then
        n
    else
        let s = n.ToString()
        let len = s.Length
        let trim = len - 2
        let trimmed = s.Substring(2, trim)
        int trimmed

let updateCustomer (custs: OrdreCustomer list) (selectedStr:string) (p: OrdreProject) =
    let cust = custs |> List.tryFind (fun c -> c.Name = Some selectedStr)
    match cust with
    | Some c -> { p with Kunde = c.Name; KundeNr = Some (trimCustNumber c.Number) }
    | None -> { p with Kunde = None; KundeNr = None }

let newProjectForm model dispatch =
    Html.form [
        Bulma.field.div [
          Bulma.label "Prosjektnr"
          Bulma.control.div [
            Bulma.input.number [
              prop.defaultValue model.NewProject.Bygg
              prop.value model.NewProject.Bygg
              prop.onTextChange (fun s -> dispatch (UpdateNewProject (fun p -> {p with Bygg = s})))
            ]
          ]
        ]
        Bulma.field.div [
          Bulma.label "Beskrivelse"
          Bulma.control.div [
            Bulma.input.text [
              prop.defaultValue model.NewProject.Beskrivelse
              prop.onTextChange (fun s -> dispatch (UpdateNewProject (fun p -> {p with Beskrivelse = s})))
            ]
          ]
        ]
        Bulma.field.div [
          Bulma.label "Kunde"
          dropDown
            (model.Customers |> List.map (fun c -> printStrOpt c.Name ))
            false
            ""
            (fun s -> dispatch (UpdateNewProject (updateCustomer model.Customers s)))
        ]
        Bulma.field.div [
          Bulma.label "Ansvarlig"
          Bulma.control.div [
            Bulma.input.text [
              prop.defaultValue (model.NewProject.Ansvarlig |> Option.defaultValue "")
              prop.onTextChange (fun s -> dispatch (UpdateNewProject (fun p -> {p with Ansvarlig = Some s})))
            ]
          ]
        ]
        Bulma.field.div [
          Bulma.label "Ordredato"
          Bulma.control.div [
            Bulma.input.date [
              prop.defaultValue (model.NewProject.OrdreDato |> Option.map (fun d -> d.ToString("yyyy-MM-dd")) |> Option.defaultValue "")
              prop.onTextChange (fun s -> dispatch (UpdateNewProject (fun p -> {p with OrdreDato = parseDateTime s})))
            ]
          ]
        ]
        Bulma.field.div [
          Bulma.label "ProsjektleiarKunde"
          Bulma.control.div [
            Bulma.input.text [
              prop.defaultValue (model.NewProject.Prosjektleiarkunde |> Option.defaultValue "")
              prop.onTextChange (fun s -> dispatch (UpdateNewProject (fun p -> {p with Prosjektleiarkunde = Some s})))
            ]
          ]
        ]
        Bulma.field.div [
          Bulma.label "KundeNr"
          Bulma.control.div [
            Bulma.input.number [
              prop.disabled true
              prop.value (model.NewProject.KundeNr |> Option.defaultValue 0)
            ]
          ]
        ]
        Bulma.field.div [
          Bulma.field.isGrouped
          prop.children [
            Bulma.control.div [
              Bulma.button.button [
                Bulma.color.isLink
                prop.text "Submit"
                prop.onClick (fun _ -> dispatch Submit)
              ]
            ]
          ]
        ]
    ]

let renderProjectRow (project: OrdreProject) =
    Html.tr
        [ prop.key project.Bygg
          prop.children
              [ Html.td project.Bygg
                Html.td project.Beskrivelse
                Html.td (printStrOpt project.Kunde)
                Html.td (printStrOpt project.Ansvarlig)
                Html.td (
                  project.OrdreDato
                  |> Option.map (fun d -> d.ToString("yyyy-MM-dd"))
                  |> Option.defaultValue "")
                Html.td (printStrOpt project.Prosjektleiarkunde)
                Html.td (project.KundeNr |> Option.defaultValue 0) ] ]

let renderProjectTable model dispatch =
    Bulma.tableContainer
        [ prop.style [ style.overflow.auto ]
          prop.children
              [ Bulma.table
                    [ table.isBordered
                      table.isStriped
                      table.isHoverable
                      prop.children
                          [ Html.thead
                                [ Html.tr
                                      [ Html.td [ str "Bygg" ]
                                        Html.td [ str "Beskrivelse" ]
                                        Html.td [ str "Kunde" ]
                                        Html.td [ str "Ansvarlig" ]
                                        Html.td [ str "Ordredato" ]
                                        Html.td [ str "ProsjektleiarKunde" ]
                                        Html.td [ str "KundeNr" ] ] ]
                            Html.tableBody
                                [ for project in model.Projects do
                                      renderProjectRow project ] ] ] ] ]


let root (model: Types.Model) (dispatch: Types.Msg -> Unit) =
    Html.div [
      Html.h1 [str model.Errors]
      newProjectForm model dispatch
      Html.br []
      renderProjectTable model dispatch ]
