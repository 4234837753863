module ProductionOrder.View

open Helpers
open FSharp.Reflection
open Types
open Fable.Core
open Fable.React
open Fable.React.Props
open Feliz
open Feliz.Bulma
open System
open System.Globalization
open Elements

let commentChanged product s =
    if String.IsNullOrEmpty s then
        OnProductChanged { product with Comment = None }
    else
        OnProductChanged { product with Comment = Some s }

let operatorChanged product s =
    if String.IsNullOrEmpty s then
        OnProductChanged { product with ApprovedBy = None }
    else
        OnProductChanged { product with ApprovedBy = Some s }

let approveClicked product _ = OrderUpdate product

let showWeldsClicked product _ = ShowWelds product

let renderWeldsModal model dispatch =
    Bulma.modal
        [ if model.ShowWelds then
              Bulma.modal.isActive
          prop.children
              [ Bulma.modalBackground [ prop.onClick (showWeldsClicked None >> dispatch) ]
                Bulma.modalContent
                    [ Bulma.box
                          [ Bulma.title.p [ Bulma.title.is4; prop.text "Welds" ]
                            Html.ul
                                [ for weld in model.Welds do
                                      Html.li weld.WeldName ] ] ]
                Bulma.modalClose [ prop.onClick (showWeldsClicked None >> dispatch) ] ] ]

let renderOperatorDropdown dispatch product =
    dropDownFromUnion
        typeof<Operators>
        true
        (product.ApprovedBy |> Option.defaultValue "")
        (operatorChanged product >> dispatch)

let renderCompletedProductRow dispatch (product: ProductionOrder) =
    Html.tr
        [ prop.children
              [ Html.td product.ProductName
                Html.td (printStrOpt product.MarkWith)
                Html.td (printStrOpt product.Comment)
                Html.td (printStrOpt product.ApprovedBy)
                Html.td (printDateOpt product.ApprovedDate)
                Html.td
                    [ Bulma.button.button
                          [ button.isText
                            prop.text "Show"
                            prop.onClick (showWeldsClicked (Some product) >> dispatch) ] ]
                Html.td
                    [ Bulma.button.button
                          [ prop.onClick (approveClicked product >> dispatch)
                            prop.children
                                [ Bulma.icon [ icon.isLarge; prop.children[Html.i [ prop.className "fas fa-times" ]] ] ] ] ] ] ]

let renderProductRow dispatch isChanged (product: ProductionOrder) =
    Html.tr
        [ let backgroundColor =
              if isChanged then
                  [ (style.backgroundColor "#fff") ]
              else
                  [ (style.backgroundColor "#fff") ]

          prop.style backgroundColor

          prop.children
              [ Html.td product.ProductName
                Html.td (product.MarkWith |> printStrOpt)
                Html.td (product.Description |> printStrOpt)
                Html.td
                    [ Html.input
                          [ prop.type'.text
                            prop.value (product.Comment |> printStrOpt)
                            prop.onTextChange (commentChanged product >> dispatch) ] ]
                Html.td [ renderOperatorDropdown dispatch product ]
                if (product.ApprovedBy.IsSome && isChanged) then
                    Html.td
                        [ Bulma.button.button
                              [ prop.style backgroundColor
                                prop.onClick (approveClicked product >> dispatch)
                                prop.children
                                    [ Bulma.icon
                                          [ Bulma.icon.isLarge
                                            prop.children[Html.i [ prop.className "fas fa-check" ]] ] ] ] ]
                else
                    Html.none ] ]

let renderTodoTable dispatch products diffProducts =
    Html.div
        [ prop.className "table-container"
          prop.children
              [ Html.table
                    [ prop.className "table is-bordered is-fullwidth"
                      prop.children
                          [ Html.thead
                                [ Html.tr
                                      [ Html.th "PartName"
                                        Html.th "PartNumber"
                                        Html.th "Description"
                                        Html.th "Comment"
                                        Html.th "Operator" ] ]
                            Html.tbody
                                [ let items = List.zip products diffProducts

                                  for product, diff in items do
                                      let isChanged = if product <> diff then true else false
                                      renderProductRow dispatch isChanged product ] ] ] ] ]

let renderCompletedTable dispatch products =
    Html.div
        [ prop.className "table-container"
          prop.children
              [ Html.table
                    [ prop.className "table is-bordered is-fullwidth"
                      prop.children
                          [ Html.thead
                                [ Html.tr
                                      [ Html.th "PartName"
                                        Html.th "PartNumber"
                                        Html.th "Comment"
                                        Html.th "Operator"
                                        Html.th "Date"
                                        Html.th "Welds" ] ]
                            Html.tbody
                                [ for product in products do
                                      renderCompletedProductRow dispatch product ] ] ] ] ]


let renderShowModeButton model dispatch =
    Html.div[prop.className "columns is-3 is-variable"

             prop.children[Html.div[prop.className "control"

                                    prop.children[label [ Style [ Display DisplayOptions.Block ] ] [ str "Show:" ]

                                                  match model.ShowMode with
                                                  | Todo ->
                                                      Bulma.button.button[Bulma.color.isLight
                                                                          prop.text "Completed"

                                                                          prop.onClick (fun _ ->
                                                                              ShowModeChanged Completed |> dispatch)]

                                                      Bulma.button.button[prop.style
                                                                              [ style.backgroundColor "#3273dc"
                                                                                style.color "#fff" ]

                                                                          prop.text "Todo"]
                                                  | Completed ->
                                                      Bulma.button.button[prop.style
                                                                              [ style.backgroundColor "#3273dc"
                                                                                style.color "#fff" ]

                                                                          prop.text "Completed"]

                                                      Bulma.button.button[Bulma.color.isLight
                                                                          prop.text "Todo"

                                                                          prop.onClick (fun _ ->
                                                                              ShowModeChanged Todo |> dispatch)]]]]]

let root model dispatch =
    Html.div
        [ prop.children
              [ Bulma.hero
                    [ Bulma.color.isInfo
                      prop.children
                          [ Bulma.heroBody[Bulma.title "Info"

                                           Html.paragraph
                                               "Deler merket med decksegment nr, må monterest på tilvarande decksegment"

                                           Html.paragraph "Ex: T-Profile 12B_T2 skal monterast på decksegment 12B"] ] ]
                Html.br []
                renderShowModeButton model dispatch
                Html.br []
                Html.h1 model.StatusMsg
                Html.div
                    [ prop.className "columns"
                      prop.children
                          [ match model.ShowMode with
                            | Todo -> renderTodoTable dispatch model.Products model.DiffProducts
                            | Completed -> renderCompletedTable dispatch model.Products ] ]
                renderWeldsModal model dispatch ] ]
