module WeldingTable.View

open Helpers
open Elmish
open Fable.React
open Feliz
open Feliz.Bulma
open WeldingTable.Types
open WeldingTable.API
open WeldingTable
open Common
open Elements
open WeldingTable.State


let showLongString (str: string) =
    match str with
    | s when s.Length > 25 -> Html.a [ tooltip.text str; prop.text (str.Substring(0, 15) + "...") ]
    | _ -> Html.p str

let showLongStringOpt (strOpt: string option) =
    match strOpt with
    | Some s -> showLongString s
    | None -> Html.none

let showWeldRow (weld: Weld) dispatch =
    Html.tr
        [ prop.key weld.Id
          prop.children
              [ Html.td (weld.WeldNr |> WeldName.value)
                Html.td (weld.Method |> WeldMethod.value)
                Html.td (weld.Type |> WeldType.value)
                Html.td (weld.Direction |> Direction.value)
                Html.td (showLongString (weld.WeldDescription |> String250.optValue))
                Html.td (showLongString (weld.Info |> String250.optValue))
                Html.td (weld.WPS |> String50.optValue)
                Html.td (weld.ThroatThickness)
                Html.td (weld.ThicknessP1)
                Html.td (weld.ThicknessP2)
                Html.td (weld.PenetrationType |> PenetrationType.value)
                Html.td (weld.Penetration)
                Html.td (weld.IsBeveled |> boolToYesNo)
                Html.td (showLongString (weld.BevelDescription |> String250.optValue))
                Html.td (weld.WIC)
                Html.td (weld.MTPT |> floatToPercentage)
                Html.td (weld.VT |> floatToPercentage)
                Html.td
                    [ Bulma.button.button
                          [ prop.onClick (fun _ -> SetEditWeldNr weld.Id |> dispatch)
                            prop.children
                                [ Bulma.icon [ icon.isLarge; prop.children[Html.i [ prop.className "fas fa-edit" ]] ] ] ] ] ] ]


let createWeldRow (weld: WeldDto) dispatch =
    Html.tr
        [ Html.td
              [ Bulma.input.text
                    [ prop.className "form-control form-control-fg"
                      prop.type' "text"
                      prop.defaultValue weld.WeldNr
                      prop.onTextChange (fun str -> SetWeldNr str |> dispatch) ] ]
          Html.td [ dropDownFromUnion typeof<WeldMethod> false (weld.Method) (fun str -> SetMethod str |> dispatch) ]
          Html.td [ dropDownFromUnion typeof<WeldType> false (weld.Type) (fun str -> SetType str |> dispatch) ]
          Html.td
              [ dropDownFromUnion typeof<Direction> false (weld.Direction) (fun str -> SetDirection str |> dispatch) ]
          Html.td
              [ Bulma.input.text
                    [ prop.className "form-control form-control-fg"
                      prop.type' "text"
                      prop.defaultValue (weld.WeldDescription |> printStrOpt)
                      prop.onTextChange (fun str -> SetWeldDescription str |> dispatch) ] ]
          Html.td
              [ Bulma.input.text
                    [ prop.className "form-control form-control-fg"
                      prop.type' "text"
                      prop.defaultValue (weld.Info |> printStrOpt)
                      prop.onTextChange (fun str -> SetInfo str |> dispatch) ] ]
          Html.td
              [ Bulma.input.text
                    [ prop.className "form-control form-control-fg"
                      prop.type' "text"
                      prop.defaultValue (weld.WPS |> printStrOpt)
                      prop.onTextChange (fun str -> SetWPS str |> dispatch) ] ]
          Html.td
              [ Bulma.input.text
                    [ prop.className "form-control form-control-fg"
                      prop.type' "text"
                      prop.defaultValue weld.ThroatThickness
                      prop.onTextChange (fun str -> SetThroatThicknes str |> dispatch) ] ]
          Html.td
              [ Bulma.input.text
                    [ prop.className "form-control form-control-fg"
                      prop.type' "text"
                      prop.defaultValue weld.ThicknessP1
                      prop.onTextChange (fun str -> SetThicknessP1 str |> dispatch) ] ]
          Html.td
              [ Bulma.input.text
                    [ prop.className "form-control form-control-fg"
                      prop.type' "text"
                      prop.defaultValue weld.ThicknessP2
                      prop.onTextChange (fun str -> SetThicknessP2 str |> dispatch) ] ]
          Html.td
              [ dropDownFromUnion
                    typeof<PenetrationType>
                    false
                    (weld.PenetrationType |> Option.defaultValue "")
                    (fun str -> SetPenetrationType str |> dispatch) ]
          Html.td
              [ Bulma.input.text
                    [ prop.className "form-control form-control-fg"
                      prop.type' "text"
                      prop.defaultValue weld.Penetration
                      prop.onTextChange (fun str -> SetPenetration str |> dispatch) ] ]
          Html.td
              [ dropDownFromUnion typeof<YesNo> false (weld.IsBeveled |> YesNo.asStr) (fun str ->
                    SetIsBeveled str |> dispatch) ]
          Html.td
              [ Bulma.input.text
                    [ prop.className "form-control form-control-fg"
                      prop.type' "text"
                      prop.defaultValue (weld.BevelDescription |> printStrOpt)
                      prop.onTextChange (fun str -> SetBevelDescription str |> dispatch) ] ]
          Html.td
              [ Bulma.input.number
                    [ prop.className "form-control form-control-fg"
                      prop.type' "number"
                      prop.defaultValue (weld.WIC)
                      prop.onTextChange (fun str -> SetWIC str |> dispatch) ] ]
          Html.td
              [ Bulma.input.text
                    [ prop.className "form-control form-control-fg"
                      prop.type' "text"
                      prop.defaultValue (weld.MTPT |> floatToPercentage)
                      prop.onTextChange (fun str -> SetMTPT str |> dispatch) ] ]
          Html.td
              [ Bulma.input.text
                    [ prop.className "form-control form-control-fg"
                      prop.type' "text"
                      prop.defaultValue (weld.VT |> floatToPercentage)
                      prop.onTextChange (fun str -> SetVT str |> dispatch) ] ]
          Html.td
              [ Bulma.button.button
                    [ prop.onClick (fun _ -> Submit |> dispatch)
                      prop.children
                          [ Bulma.icon [ icon.isLarge; prop.children[Html.i [ prop.className "fas fa-save" ]] ] ] ] ]
          Html.td
              [ Bulma.button.button
                    [ prop.onClick (fun _ -> SetEditWeldNr 0 |> dispatch)
                      prop.children
                          [ Bulma.icon [ icon.isLarge; prop.children[Html.i [ prop.className "fas fa-times" ]] ] ] ] ]

          ]


let headerFooterContent =
    [ Html.tr
          [ Html.th[prop.colSpan 7
                    prop.children [ str "Weld" ]]
            Html.th[prop.colSpan 3
                    prop.children [ str "Thickness(mm)" ]]
            Html.th[prop.colSpan 2
                    prop.children [ str "Penetration" ]]
            Html.th[prop.colSpan 2
                    prop.children [ str "Bevel" ]]
            Html.th[prop.colSpan 3
                    prop.children [ str "NDT" ]] ]
      Html.tr
          [ Html.th "Name"
            Html.th "Method"
            Html.th "Type"
            Html.th "Direction"
            Html.th "WeldDesc"
            Html.th "Info"
            Html.th "WPS"
            Html.th "Throat"
            Html.th "P1"
            Html.th "P2"
            Html.th "Type"
            Html.th "Depth(mm)"
            Html.th "Yes/No"
            Html.th "Description"
            Html.th "WIC"
            Html.th "MTPT"
            Html.th "VT" ] ]

let renderWeldTable model dispatch =
    Bulma.tableContainer
        [ prop.style [ style.overflow.auto ]
          prop.children
              [ Bulma.table
                    [ table.isBordered
                      table.isStriped
                      table.isHoverable
                      prop.children
                          [ Html.thead headerFooterContent
                            if model.Welds.Length > 10 then
                                Html.tfoot (headerFooterContent |> List.rev)
                            Html.tbody
                                [ for weld in model.Welds do
                                      if model.EditWeldId = weld.Id then
                                          createWeldRow (model.NewWeld) dispatch
                                      else
                                          showWeldRow weld dispatch

                                  if model.EditWeldId = 0 then
                                      createWeldRow model.NewWeld dispatch
                                  else
                                      Html.none ] ] ] ] ]

let projectSelector model dispatch =
    Bulma.control.div
        [ prop.className "column is-one-fifth field has-addons"
          prop.children
              [ Bulma.control.div
                    [ prop.className "control"
                      prop.children
                          [ Bulma.input.number
                                [ prop.value (model.ProjectNr.ToString())
                                  prop.onTextChange (fun str -> UpdateProjNumber str |> dispatch)
                                  prop.onKeyDown (fun ev ->
                                      if ev.key = "Enter" then
                                          ev.stopPropagation ()
                                          LoadWelds |> dispatch) ] ] ]
                Bulma.control.div
                    [ prop.className "control"
                      prop.children
                          [ Bulma.button.button
                                [ prop.onClick (fun _ -> LoadWelds |> dispatch)
                                  prop.children
                                      [ Bulma.icon
                                            [ icon.isLarge; prop.children[Html.i [ prop.className "fas fa-search" ]] ] ] ] ] ] ] ]

let root (model: Model) (dispatch: Msg -> unit) =
    Html.div
        [ prop.children
              [ Html.h1 model.StatusMsg
                projectSelector model dispatch
                renderWeldTable model dispatch
                errorsList model.Errors ] ]
