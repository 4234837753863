module NDT.Types

open System
open Thoth.Json
open ProductionOrder.Types

type ShowMode =
    | Todo
    | Completed

type NDTProduct =
    { PartName: string
      PartNumber: string
      PLMID: string
      CompletedWelds: Weld list }
    static member Decoder =
        Decode.object
            (fun get ->
                {
                    PartName = get.Required.Field "PartName" Decode.string
                    PartNumber = get.Required.Field "PartNumber" Decode.string
                    PLMID = get.Required.Field "PLMID" Decode.string
                    CompletedWelds = get.Required.Field "Welds" (Decode.list Weld.Decoder)
                })

    static member Encode(p: NDTProduct) = Encode.Auto.toString (0, p)


type Model =
    { ShowMode: ShowMode
      EditMode: bool
      SelectedPOS: ProductionOrder array
      SelectedWelds: WeldingTable.Types.Weld list
      SelectedNDT: NDTProduct option
      NDTProducts: NDTProduct list
      StatusMsg: string }

type Msg =
    | Init
    | Refresh
    | OnRefreshed of NDTProduct list
    | OnPOLoaded of ProductionOrder array
    | OnWeldsLoaded of WeldingTable.Types.Weld list
    | OnError of string
    | ToggleEditMode of bool
    | ShowModeChanged of ShowMode
    | ShowNDT of NDTProduct option
