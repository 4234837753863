module Home.View

open Fable.Core
open Fable.Core.JsInterop
open Fable.React
open Fable.React.Props
open Types

let root model dispatch =
  div [] [ 
    span [] [ 
      str "Hei her er ein webviewer for Prodtex datainnsamling" 
    ] 
  ]
