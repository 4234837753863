module App.State

open Elmish
open Elmish.Navigation
open Elmish.UrlParser
open Browser
open Global
open Types
open ProductionOrder.Types

let pageParser: Parser<Page -> Page, Page> =
    oneOf
        [ map Sveisetrad (s "sveisetrad")
          map Lasersveis (s "lasersveis")
          map Laserlog (s "laserlog")
          map GantryCell (s "gantrycell")
          map GantryCell2 (s "gantrycellto")
          map MIGMaerskCell (s "migmaerskcell")
          map IGMCell (s "igmcell")
          map ManualCell (s "manualcell")
          map FlyttbarCell (s "flyttbarcell")
          map WeldTable (s "weldtable")
          map NDT (s "ndt")
          map OrdreProjects (s "ordreproject")
          map OrdreCustomers (s "ordrecustomer")
          map OrdreProjectsIndustri (s "ordreprojectindustri")
          map OrdreCustomersIndustri (s "ordrecustomerindustri")
          map Home (s "home") ]

let urlUpdate (result: Page option) model =
    match result with
    | None ->
        console.error ("Error parsing url")
        model, Navigation.modifyUrl (toHash model.CurrentPage)
    | Some page ->
        // Initialize data for the page you are navigating by passing command
        let cmd =
            match page with
            | GantryCell -> Cmd.map GantryCellMsg (Cmd.ofMsg ProductionOrder.Types.Init)
            | GantryCell2 -> Cmd.map GantryCell2Msg (Cmd.ofMsg ProductionOrder.Types.Init)
            | MIGMaerskCell -> Cmd.map MIGMaerskCellMsg (Cmd.ofMsg ProductionOrder.Types.Init)
            | IGMCell -> Cmd.map IGMCellMsg (Cmd.ofMsg ProductionOrder.Types.Init)
            | ManualCell -> Cmd.map ManualCellMsg (Cmd.ofMsg ProductionOrder.Types.Init)
            | FlyttbarCell -> Cmd.map FlyttbarCellMsg (Cmd.ofMsg ProductionOrder.Types.Init)
            | Laserlog -> Cmd.map LaserLogMsg (Cmd.ofMsg Laserlog.Types.Initialize)
            | Lasersveis -> Cmd.map LaserSveisMsg (Cmd.ofMsg Lasersveis.Types.Initialize)
            | Sveisetrad -> Cmd.map SveisetradMsg (Cmd.ofMsg Sveisetrad.Types.Initialize)
            | WeldTable -> Cmd.map WeldTableMsg (Cmd.ofMsg WeldingTable.Types.Init)
            | NDT -> Cmd.map NDTMsg (Cmd.ofMsg NDT.Types.Init)
            | OrdreProjects -> Cmd.map OrdreProjectMsg (Cmd.ofMsg OrdreProjects.Types.Init)
            | OrdreCustomers -> Cmd.map OrdreCustomerMsg (Cmd.ofMsg OrdreCustomers.Types.Init)
            | OrdreProjectsIndustri -> Cmd.map OrdreProjectIndustriMsg (Cmd.ofMsg OrdreProjects.Types.Init)
            | OrdreCustomersIndustri -> Cmd.map OrdreCustomerIndustriMsg (Cmd.ofMsg OrdreCustomers.Types.Init)
            | Home -> Cmd.none

        { model with CurrentPage = page }, cmd

let init result =
    let (login, logincmd) = Login.init ()
    let (lasersveis, lasersveisCmd) = Lasersveis.State.init ()
    let (laserlog, laserlogCmd) = Laserlog.State.init ()
    let (sveisetrad, sveisetradCmd) = Sveisetrad.State.init ()
    let (gantrycell, gantrycellCmd) = ProductionOrder.State.init ()
    let (gantrycell2, gantrycell2Cmd) = ProductionOrder.State.init ()
    let (migmearskcell, migmaerskCmd) = ProductionOrder.State.init ()
    let (igmcell, igmCellCmd) = ProductionOrder.State.init ()
    let (manualcell, manualcellCmd) = ProductionOrder.State.init ()
    let (flyttbarcell, flyttbarcellCmd) = ProductionOrder.State.init ()
    let (weldTable, weldTableCmd) = WeldingTable.State.init ()
    let (ndt, ndtCmd) = NDT.State.init ()
    let (ordreproject, ordreprojectCmd) = OrdreProjects.State.init ()
    let (ordrecustomer, ordrecustomerCmd) = OrdreCustomers.State.init ()
    let (ordreprojectindustri, ordreprojectindustriCmd) = OrdreProjects.State.init ()
    let (ordrecustomerindustri, ordrecustomerindustriCmd) = OrdreCustomers.State.init ()
    let (home, homeCmd) = Home.State.init ()

    let (model, cmd) =
        urlUpdate
            result
            { CurrentPage = Home
              Login = login
              Session = None
              Sveisetrad = sveisetrad
              Lasersveis = lasersveis
              Laserlog = laserlog
              GantryCell = gantrycell
              GantryCell2 = gantrycell2
              MIGMaerskCell = migmearskcell
              IGMCell = igmcell
              ManualCell = manualcell
              FlyttbarCell = flyttbarcell
              WeldTable = weldTable
              NDT = ndt
              OrdreProject = ordreproject
              OrdreCustomer = ordrecustomer
              OrdreProjectIndustri = ordreprojectindustri
              OrdreCustomerIndustri = ordrecustomerindustri
              Home = home }

    model,
    Cmd.batch
        [ cmd
          Cmd.map LoginMsg (Cmd.ofMsg Login.GetUserInfo)
          Cmd.map LoginMsg logincmd
          Cmd.map SveisetradMsg sveisetradCmd
          Cmd.map LaserSveisMsg lasersveisCmd
          Cmd.map LaserLogMsg laserlogCmd
          Cmd.map GantryCellMsg gantrycellCmd
          Cmd.map GantryCell2Msg gantrycell2Cmd
          Cmd.map MIGMaerskCellMsg migmaerskCmd
          Cmd.map IGMCellMsg igmCellCmd
          Cmd.map ManualCellMsg manualcellCmd
          Cmd.map FlyttbarCellMsg flyttbarcellCmd
          Cmd.map WeldTableMsg weldTableCmd
          Cmd.map NDTMsg ndtCmd
          Cmd.map OrdreProjectMsg ordreprojectCmd
          Cmd.map OrdreCustomerMsg ordrecustomerCmd
          Cmd.map OrdreProjectIndustriMsg ordreprojectindustriCmd
          Cmd.map OrdreCustomerIndustriMsg ordrecustomerindustriCmd
          Cmd.map HomeMsg homeCmd ]

let update msg model =
    match msg with
    | LoginMsg msg ->
        let (login, loginCmd, loginExtMsg) = Login.update msg model.Login

        let newModel =
            match loginExtMsg with
            | Login.ExtMsg.SignedIn session -> { model with Session = Some session }
            | _ -> model

        { newModel with Login = login }, Cmd.map LoginMsg loginCmd
    | HomeMsg msg ->
        let (home, homeCmd) = Home.State.update msg model.Home
        { model with Home = home }, Cmd.map HomeMsg homeCmd
    | SveisetradMsg msg ->
        let (sveisetrad, sveisetradCmd) = Sveisetrad.State.update msg model.Sveisetrad
        { model with Sveisetrad = sveisetrad }, Cmd.map SveisetradMsg sveisetradCmd
    | LaserSveisMsg msg ->
        let (lasersveis, lasersveisCmd) = Lasersveis.State.update msg model.Lasersveis
        { model with Lasersveis = lasersveis }, Cmd.map LaserSveisMsg lasersveisCmd
    | LaserLogMsg msg ->
        let (laserlog, laserlogCmd) = Laserlog.State.update msg model.Laserlog
        { model with Laserlog = laserlog }, Cmd.map LaserLogMsg laserlogCmd
    | GantryCellMsg msg ->
        let (gantrycell, gantrycellCmd) =
            ProductionOrder.State.update FiskaGantry msg model.GantryCell

        { model with GantryCell = gantrycell }, Cmd.map GantryCellMsg gantrycellCmd
    | GantryCell2Msg msg ->
        let (gantrycell2, gantrycell2Cmd) =
            ProductionOrder.State.update FiskaGantry2 msg model.GantryCell2

        { model with GantryCell2 = gantrycell2 }, Cmd.map GantryCell2Msg gantrycell2Cmd
    | MIGMaerskCellMsg msg ->
        let (migmaerskcell, migmaerskcellCmd) =
            ProductionOrder.State.update MIGMaersk msg model.MIGMaerskCell

        { model with
            MIGMaerskCell = migmaerskcell },
        Cmd.map MIGMaerskCellMsg migmaerskcellCmd
    | IGMCellMsg msg ->
        let (igmcell, igmCellCmd) = ProductionOrder.State.update IGM msg model.IGMCell
        { model with IGMCell = igmcell }, Cmd.map IGMCellMsg igmCellCmd
    | ManualCellMsg msg ->
        let (manualcell, manualcellCmd) =
            ProductionOrder.State.update Manual msg model.ManualCell

        { model with ManualCell = manualcell }, Cmd.map ManualCellMsg manualcellCmd
    | FlyttbarCellMsg msg ->
        let (flyttbarcell, flyttbarcellCmd) =
            ProductionOrder.State.update Flyttbar msg model.FlyttbarCell

        { model with
            FlyttbarCell = flyttbarcell },
        Cmd.map FlyttbarCellMsg flyttbarcellCmd
    | WeldTableMsg msg ->
        let (weldTable, weldTableCmd) = WeldingTable.State.update msg model.WeldTable
        { model with WeldTable = weldTable }, Cmd.map WeldTableMsg weldTableCmd
    | NDTMsg msg ->
        let (ndt, ndtCmd) = NDT.State.update msg model.NDT
        { model with NDT = ndt }, Cmd.map NDTMsg ndtCmd
    | OrdreProjectMsg msg ->
        let (ordreproject, ordreprojectCmd) =
            OrdreProjects.State.update OrdreProjects.Types.Prodtex.AS msg model.OrdreProject

        { model with
            OrdreProject = ordreproject },
        Cmd.map OrdreProjectMsg ordreprojectCmd
    | OrdreCustomerMsg msg ->
        let (ordrecustomer, ordrecustomerCmd) =
            OrdreCustomers.State.update OrdreCustomers.Types.Prodtex.AS msg model.OrdreCustomer

        { model with
            OrdreCustomer = ordrecustomer },
        Cmd.map OrdreCustomerMsg ordrecustomerCmd

    | OrdreProjectIndustriMsg msg ->
        let (ordreprojectindustri, ordreprojectindustriCmd) =
            OrdreProjects.State.update OrdreProjects.Types.Prodtex.Industri msg model.OrdreProjectIndustri

        { model with
            OrdreProjectIndustri = ordreprojectindustri },
        Cmd.map OrdreProjectIndustriMsg ordreprojectindustriCmd

    | OrdreCustomerIndustriMsg msg ->
        let (ordrecustomerindustri, ordrecustomerindustriCmd) =
            OrdreCustomers.State.update OrdreCustomers.Types.Prodtex.Industri msg model.OrdreCustomerIndustri

        { model with
            OrdreCustomerIndustri = ordrecustomerindustri },
        Cmd.map OrdreCustomerIndustriMsg ordrecustomerindustriCmd
