module Laserlog.View

open Fable.Core
open Fable.React
open Fable.React.Props
open Feliz
open Feliz.Recharts
open Feliz.Bulma
open Types
open System
open System.Globalization


let msToS v = v/1000

let showGraphClicked logentry _ =
    ShowGraph logentry

let windowSizeChanged value = 
    WindowSizeChanged value
    

let averageInt listOfInt =
    listOfInt
    |> List.map float
    |> List.average
    |> int

let powerChart (samples:int list) windowSize =
    let timeline = [for i in 1 .. samples.Length do yield i] 
    let movingAvg =
        samples
        |> List.windowed windowSize
        |> List.map (fun w -> w |> averageInt)
        |> List.append
            [for i in 1..windowSize-1 do
                samples |> List.take i |> averageInt]
    
    let data = List.zip3 timeline samples movingAvg
    let minY = (samples |> averageInt) - 100
    let maxY = (samples |> List.max) + 100
    Recharts.lineChart [
        lineChart.width 950
        lineChart.height 400
        lineChart.data data
        lineChart.margin(top=5, right=30)
        lineChart.children [
            Recharts.cartesianGrid [ cartesianGrid.strokeDasharray(5,5)]
            Recharts.xAxis [xAxis.dataKey (fun ((t:int),_,_) -> t)]
            Recharts.yAxis [yAxis.domain(domain.constant minY,domain.constant maxY); yAxis.allowDataOverflow true]
            Recharts.tooltip []
            Recharts.legend []
            Recharts.line [
                line.monotone
                line.step
                line.dot false
                line.dataKey (fun (_, (s:int),_) -> s)
                line.stroke "#ff4a4a"
                line.name "Raw(W)"
            ]
            Recharts.line [
                line.monotone
                line.step
                line.dot false
                line.dataKey (fun (_,_,(a:int)) -> a)
                line.stroke "#4a7aff"
                line.name "Average(W)"
            ]
        ]
    ]


let renderGraphModal model dispatch =
    Bulma.modal [
        if model.ShowGraph then Bulma.modal.isActive
        prop.children [
            Bulma.modalBackground [
                prop.onClick (showGraphClicked None >> dispatch)
                prop.width 1000
            ]
            Bulma.modalContent [
                Bulma.box [
                    Bulma.title.p [
                        Bulma.title.is4
                        prop.text "Powerlog:"
                    ]
                    Slider.slider [
                        slider.isFullWidth
                        prop.defaultValue model.WindowSize
                        prop.onChange (windowSizeChanged >> dispatch)
                        prop.step 1
                    ]
                    Html.p [
                        let windowSizeInSec = float model.WindowSize / 7.99
                        str (sprintf "Average over: %.1f sec" windowSizeInSec)
                    ]
                    if model.PowerLog <> [] then
                        powerChart model.PowerLog model.WindowSize
                ]
            ]
            Bulma.modalClose [ prop.onClick (showGraphClicked None >> dispatch) ] 
        ]
    ]

let showLaserlogTableRow dispatch (logentry:Laserlog) =
    Html.tr [
        prop.children [
            Html.td logentry.Id
            Html.td (logentry.DateTime.ToString("yyyy-MM-dd HH.mm.ss"))
            Html.td (sprintf "%is" (logentry.Duration |> msToS))
            Html.td logentry.LightPath
            Html.td logentry.LaserProgram
            Html.td logentry.Laserpower
            Html.td [
                Bulma.button.button [
                    Bulma.button.isText
                    prop.text "Show"
                    prop.onClick (showGraphClicked (Some logentry) >> dispatch)
                ]
            ]
        ]
    ]

let showLaserLogTable dispatch (laserlog:Laserlog list) =
    Html.div[
        prop.className "table-container"
        prop.children [
            Html.table[
                prop.className "table is-bordered is-fullwidth"
                prop.children[
                    Html.thead[
                        Html.tr[
                            Html.th "Id"
                            Html.th "Date"
                            Html.th "Duration"
                            Html.th "LP"
                            Html.th "Prg"
                            Html.th "Pwr"
                        ]
                    ]
                    Html.tbody[
                        for logentry in laserlog do
                            showLaserlogTableRow dispatch logentry 
                    ]
                ]
            ]
        ]
    ]

let showDateRangePicker model dispatch =
    div [ClassName "columns"; Style [Padding "1.5rem"; FontWeight "700"]] [
        div [ClassName "column is-offset-one-quarter"] [
            form [ClassName "box"] [
                div [ClassName "Field"] [
                    label [Style [Display DisplayOptions.Block]] [str "From Date"]
                    div [ClassName "control"] [
                        Html.input [
                            prop.className "input"
                            prop.style [style.borderWidth 1; style.borderStyle.solid; style.borderColor "#dbdbdb" ]
                            prop.type' "date"
                            prop.value (model.FromDate.ToString("yyyy-MM-dd"))
                            prop.placeholder (DateTime.Now.AddDays(-20.).ToString("dd-MM-yyyy"))
                            prop.onChange (fun v -> dispatch (FromDateChanged v))
                        ]
                    ]
                ]
                div [ClassName "Field"] [
                    label [] [str "To Date"]
                    div [ClassName "control"] [
                        Html.input [
                            prop.className "input"
                            prop.style [style.borderWidth 1; style.borderStyle.solid; style.borderColor "#dbdbdb" ]
                            prop.type' "date"
                            prop.value (model.ToDate.ToString("yyyy-MM-dd"))
                            prop.placeholder (sprintf "%s" (DateTime.Now.ToString("dd-MM-yyyy")))
                            prop.onChange (fun v -> dispatch (ToDateChanged v))
                        ]
                    ]
                ]
            ]
        ]
    ]

let root (model:Model) dispatch =
    Html.div[
        prop.children [
            Html.div[
                prop.className "columns is-3 is-variable"
                prop.children[
                    Html.div[
                        prop.className "control"
                        prop.children[
                            label [Style [Display DisplayOptions.Block]] [str "Search Mode:"]
                            match model.SearchMode with
                            | DateRange ->
                                Bulma.button.button[
                                    Bulma.color.isLight
                                    prop.text "Top 20"
                                    prop.onClick (fun e -> SearchModeChanged Top20 |> dispatch)
                                ]
                                Bulma.button.button[
                                    prop.style [style.backgroundColor "#3273dc"; style.color "#fff"]
                                    prop.text "DateRange"
                                ]
                            | Top20 ->
                                Bulma.button.button[
                                    prop.style [style.backgroundColor "#3273dc"; style.color "#fff"]
                                    prop.text "Top 20"
                                ]
                                Bulma.button.button[
                                    Bulma.color.isLight
                                    prop.text "DateRange"
                                    prop.onClick (fun e -> SearchModeChanged DateRange |> dispatch)
                            ]
                        ]
                    ]
                ]
            ]
            if model.SearchMode = DateRange then
                Html.div[
                    prop.className "columns"
                    prop.children [
                        showDateRangePicker model dispatch
                    ]
                ]
            else
                Html.none

            Html.br[]
            Html.div[
                prop.className "columns"
                prop.children [
                    showLaserLogTable dispatch model.Laserlog
                ]
            ]
            renderGraphModal model dispatch
        ]
    ]
