module Lasersveis.State

open Elmish
open Types
open System
open Thoth.Json
open Thoth.Elmish
open Fable.SimpleHttp

let init () : Model * Cmd<Msg> =
    { FromDate = DateTime.Now.AddDays(-20.)
      ToDate = DateTime.Now
      Lasersveis = []
      ErrorMsg = ""
      UnchangedLasersveis = []
      SearchMode = Top20 },
    Cmd.none

// API calls
let refreshList successHandler errorHandler =
    async {
        let! result = API.getTop20Lasersvies

        match result with
        | Ok data -> return successHandler data
        | Error e -> return errorHandler e
    }
    |> Cmd.OfAsync.result

let refreshListFromTo (fromDate: DateTime, toDate: DateTime) successHandler errorHandler =
    async {
        let! result = API.getLasersviesFromTo (fromDate, toDate)

        match result with
        | Ok data -> return successHandler data
        | Error e -> return errorHandler e
    }
    |> Cmd.OfAsync.result

let applyManualClassification
    (id: int)
    (manualClassification: bool option)
    (manualClassificationDescription: string option)
    successHandler
    errorHandler
    =
    async {
        let! result = API.putManualClassification id manualClassification manualClassificationDescription

        match result with
        | Ok data -> return successHandler data
        | Error e -> return errorHandler e
    }
    |> Cmd.OfAsync.result

// Helper functions for working with Lasersveis list
let updateClassificationById id classification (lasersveis: Lasersveis list) =
    lasersveis
    |> List.map
        (fun l ->
            if l.Id = id then
                { l with
                      ManualClassification =
                          match classification with
                          | OK -> Some true
                          | NOK -> Some false
                          | NE -> None }
            else
                l)

let updateDescriptionById id description (lasersveis: Lasersveis list) =
    lasersveis
    |> List.map
        (fun l ->
            if l.Id = id then
                { l with
                      ManualClassificationDescription = description }
            else
                l)

// Handle app messages
let update msg model =
    match msg with
    // Initialize/Refresh data
    | Initialize -> model, refreshList OnRefreshed OnError
    | Refresh -> model, refreshList OnRefreshed OnError
    //model, refreshList
    | OnRefreshed data ->
        { model with
              Lasersveis = data
              UnchangedLasersveis = data },
        Cmd.none
    | OnListRefreshedError e ->
        { model with
              Lasersveis = []
              UnchangedLasersveis = [] },
        Cmd.none
    | OnError s -> { model with ErrorMsg = s }, Cmd.none

    // User input
    | SearchModeChanged m ->
        match m with
        | Top20 ->
            { model with
                  SearchMode = m
                  Lasersveis = []
                  UnchangedLasersveis = [] },
            refreshList OnRefreshed OnListRefreshedError
        | DateRange ->
            { model with
                  SearchMode = m
                  Lasersveis = []
                  UnchangedLasersveis = [] },
            refreshListFromTo (model.FromDate, model.ToDate) OnRefreshed OnListRefreshedError
    | FromDateChanged s ->
        let isOk, date = DateTime.TryParse s

        if isOk then
            { model with
                  FromDate = date
                  Lasersveis = []
                  UnchangedLasersveis = [] },
            refreshListFromTo (date, model.ToDate) OnRefreshed OnListRefreshedError
        else
            model, Cmd.none
    | ToDateChanged s ->
        let isOk, date = DateTime.TryParse s

        if isOk then
            { model with
                  ToDate = date
                  Lasersveis = []
                  UnchangedLasersveis = [] },
            refreshListFromTo (model.FromDate, date) OnRefreshed OnError
        else
            model, Cmd.none
    | OnClassificationChanged (id, status) ->
        { model with
              Lasersveis =
                  model.Lasersveis
                  |> updateClassificationById id status },
        Cmd.none
    | OnClassificaitonDescriptionChanged (id, desc) ->
        { model with
              Lasersveis =
                  model.Lasersveis
                  |> updateDescriptionById id (Some desc) },
        Cmd.none

    // Update DB with new data
    | ClassificationUpdate (id) ->
        let entry =
            model.Lasersveis |> List.find (fun e -> e.Id = id)

        model,
        applyManualClassification
            id
            entry.ManualClassification
            entry.ManualClassificationDescription
            ClassificationUpdateSucceded
            ClassificationUpdateError
    | ClassificationUpdateSucceded data ->
        let classification =
            data.ManualClassification |> Classification.create

        let updatedList =
            model.Lasersveis
            |> updateClassificationById data.Id classification
            |> updateDescriptionById data.Id data.ManualClassificationDescription

        let updatedUnchangedList =
            model.UnchangedLasersveis
            |> updateClassificationById data.Id classification
            |> updateDescriptionById data.Id data.ManualClassificationDescription

        { model with
              Lasersveis = updatedList
              UnchangedLasersveis = updatedUnchangedList },
        Cmd.none
    | ClassificationUpdateError e -> model, Cmd.none
