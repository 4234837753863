module ProductionOrder.Types

open System
open Thoth.Json

type Operators =
    | GJN
    | MM
    | SDL
    | ABK
    | SS
    | GRZ
    | LAT
    | OT
    | HD
    | BR
    | RSH

type Cells =
    | FiskaGantry
    | FiskaGantry2
    | MIGMaersk
    | MIGFanuc
    | Manual
    | Flyttbar
    | IGM

let cellStr cell =
    match cell with
    | FiskaGantry -> "GantryCell"
    | FiskaGantry2 -> "GantryCell2"
    | MIGFanuc -> "MigFanuc"
    | MIGMaersk -> "MigMaersk"
    | Manual -> "Manual"
    | Flyttbar -> "Flyttbar"
    | IGM -> "IGM"

type ProductionOrder =
    { Id: int
      ProductName: string
      ProductPlmid: string
      Approved: bool
      ApprovedBy: string option
      ApprovedDate: DateTime option
      Description: string option
      Comment: string option
      Priority: int option
      Cell: string
      MarkWith: string option }

    static member Decoder =
        Decode.object (fun get ->
            { Id = get.Required.Field "Id" Decode.int
              ProductName = get.Required.Field "ProductName" Decode.string
              ProductPlmid = get.Required.Field "ProductPlmid" Decode.string
              Approved = get.Required.Field "Approved" Decode.bool
              ApprovedBy = get.Optional.Field "ApprovedBy" Decode.string
              ApprovedDate = get.Optional.Field "ApprovedDate" DecodeSpecial.datetime
              Description = get.Optional.Field "Description" Decode.string
              Comment = get.Optional.Field "Comment" Decode.string
              Priority = get.Optional.Field "Priority" Decode.int
              Cell = get.Required.Field "Cell" Decode.string
              MarkWith = get.Optional.Field "MarkWith" Decode.string })

    static member Encoder(po: ProductionOrder) = Encode.Auto.toString (0, po)

type Weld =
    { Id: int
      WeldId: int
      ProductName: string
      WeldName: string
      ProductionOrderId: int
      NDTDate: DateTime option
      NDTDefects: int
      NDTComments: string option
      NDTFillerMatBatch: string }

    static member Decoder =
        Decode.object (fun get ->
            { Id = get.Required.Field "Id" Decode.int
              WeldId = get.Required.Field "WeldId" Decode.int
              ProductName = get.Required.Field "ProductName" Decode.string
              WeldName = get.Required.Field "WeldName" Decode.string
              ProductionOrderId = get.Required.Field "ProductionOrderId" Decode.int
              NDTDate = get.Optional.Field "NDTDate" DecodeSpecial.datetime
              NDTDefects = get.Required.Field "Ndtdefects" Decode.int
              NDTComments = get.Optional.Field "NDTComments" Decode.string
              NDTFillerMatBatch = get.Required.Field "NdtfillerMatBatch" Decode.string })

    static member Encode(w: Weld) = Encode.Auto.toString (0, w)


type ShowMode =
    | Todo
    | Completed

type Model =
    { Products: ProductionOrder list
      DiffProducts: ProductionOrder list
      ShowMode: ShowMode
      ShowWelds: bool
      Welds: Weld list
      StatusMsg: string }

type Msg =
    | Init
    | Refresh
    | OnRefreshed of (ProductionOrder list * ProductionOrder list)
    | OnError of string

    | ShowModeChanged of ShowMode
    | ShowWelds of ProductionOrder option
    | OnWeldsUpdated of Weld list

    | OnProductChanged of ProductionOrder
    | OrderUpdate of ProductionOrder
    | OrderUpdateSuccess of ProductionOrder
