module App.View

open UserInfo
open Elmish
open Elmish.Navigation
open Elmish.UrlParser
open Fable.Core
open Fable.Core.JsInterop
open Types
open App.State
open Global

importAll "../sass/main.sass"

open Fable.React
open Fable.React.Props
open ProductionOrder.Types


let menuItem label page currentPage =
    li [] [ a [ classList [ "is-active", page = currentPage ]; Href(toHash page) ] [ str label ] ]

let menu currentPage (session: UserInfo option) =
    aside
        [ ClassName "menu" ]
        [ p [ ClassName "menu-label" ] [ str "Meny" ]
          ul
              [ ClassName "menu-list" ]
              [ menuItem "Home" Home currentPage
                match session with
                | None -> ()
                | Some user ->
                    if user.UserRoles |> Array.contains "user" then
                        menuItem "Sveisetråd log" Sveisetrad currentPage
                        menuItem "Lasersveis" Lasersveis currentPage
                        menuItem "Laserlog" Laserlog currentPage
                        menuItem "GantryCell" GantryCell currentPage
                        menuItem "GantryCell2" GantryCell2 currentPage
                        menuItem "MIGMaerskCell" MIGMaerskCell currentPage
                        menuItem "IGMCell" IGMCell currentPage
                        menuItem "ManualCell" ManualCell currentPage
                        menuItem "FlyttbarCell" FlyttbarCell currentPage
                        menuItem "WeldTable" WeldTable currentPage

                    if user.UserRoles |> Array.contains "project" then
                        menuItem "Project" OrdreProjects currentPage
                        menuItem "Customer" OrdreCustomers currentPage
                        menuItem "Industri Project" OrdreProjectsIndustri currentPage
                        menuItem "Industri Customer" OrdreCustomersIndustri currentPage
                // menuItem "NDT" NDT currentPage
                ] ]

let root model dispatch =

    let pageHtml page =
        match page with
        | Sveisetrad -> Sveisetrad.View.root model.Sveisetrad (SveisetradMsg >> dispatch)
        | Home -> Home.View.root model.Home (HomeMsg >> dispatch)
        | Lasersveis -> Lasersveis.View.root model.Lasersveis (LaserSveisMsg >> dispatch)
        | Laserlog -> Laserlog.View.root model.Laserlog (LaserLogMsg >> dispatch)
        | GantryCell -> ProductionOrder.View.root model.GantryCell (GantryCellMsg >> dispatch)
        | GantryCell2 -> ProductionOrder.View.root model.GantryCell2 (GantryCell2Msg >> dispatch)
        | MIGMaerskCell -> ProductionOrder.View.root model.MIGMaerskCell (MIGMaerskCellMsg >> dispatch)
        | IGMCell -> ProductionOrder.View.root model.IGMCell (IGMCellMsg >> dispatch)
        | ManualCell -> ProductionOrder.View.root model.ManualCell (ManualCellMsg >> dispatch)
        | FlyttbarCell -> ProductionOrder.View.root model.FlyttbarCell (FlyttbarCellMsg >> dispatch)
        | WeldTable -> WeldingTable.View.root model.WeldTable (WeldTableMsg >> dispatch)
        | OrdreProjects -> OrdreProjects.View.root model.OrdreProject (OrdreProjectMsg >> dispatch)
        | OrdreCustomers -> OrdreCustomers.View.root model.OrdreCustomer (OrdreCustomerMsg >> dispatch)
        | OrdreProjectsIndustri -> OrdreProjects.View.root model.OrdreProjectIndustri (OrdreProjectIndustriMsg >> dispatch)
        | OrdreCustomersIndustri -> OrdreCustomers.View.root model.OrdreCustomerIndustri (OrdreCustomerIndustriMsg >> dispatch)
        | NDT -> NDT.View.root model.NDT (NDTMsg >> dispatch)

    div
        []
        [ Navbar.View.root model.Session
          div
              [ ClassName "section" ]
              [ div
                    [ ClassName "container is-fluid" ]
                    [ div
                          [ ClassName "columns" ]
                          [ div [ ClassName "column is-2" ] [ menu model.CurrentPage model.Session ]
                            div [ ClassName "column is-10" ] [ pageHtml model.CurrentPage ] ] ] ] ]

open Elmish.React
open Elmish.Debug
open Elmish.HMR

// App
Program.mkProgram init update root
|> Program.toNavigable (parseHash pageParser) urlUpdate
#if DEBUG
|> Program.withDebugger
#endif
|> Program.withReactBatched "elmish-app"
|> Program.run
