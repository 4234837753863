module WeldingTable.Types

open Common


type WeldName = WeldName of string

module WeldName =
    let value (WeldName name) = name

    let create fieldName name =
        let pattern = "^W\d{1,9}\+?$"
        ConstrainedType.createLike fieldName WeldName pattern name

type Direction =
    | Horizontal
    | Vertical
    | Other

module Direction =
    let value status =
        match status with
        | Horizontal -> "Horizontal"
        | Vertical -> "Vertical"
        | Other -> "Other"

    let create fieldName str =
        match str with
        | "Horizontal"
        | "horizontal" -> Ok Horizontal
        | "Vertical"
        | "vertical" -> Ok Vertical
        | "Other"
        | "other" -> Ok Other
        | _ ->
            // all other cases
            let msg = sprintf "%s: Must be one of 'Horizontal', 'Vertical', 'Other'" fieldName
            Error msg

type YesNo =
    | Yes
    | No

module YesNo =
    let value x =
        match x with
        | Yes -> true
        | No -> false

    let asStr =
        function
        | true -> "Yes"
        | false -> "No"

    let create fieldName str =
        match str with
        | "yes"
        | "Yes" -> Ok Yes
        | "no"
        | "No" -> Ok No
        | _ ->
            let msg = sprintf "%s: Must be one of 'Yes', 'No'" fieldName
            Error msg

type WeldMethod =
    | LaserHybrid
    | MigRobot
    | MigManual
    | Laser
    | TIG
    | ArcStud

module WeldMethod =
    let value status =
        match status with
        | LaserHybrid -> "LaserHybrid"
        | MigRobot -> "MigRobot"
        | MigManual -> "MigManual"
        | Laser -> "Laser"
        | TIG -> "TIG"
        | ArcStud -> "ArcStud"

    let create fieldName str =
        match str with
        | "LaserHybrid"
        | "laserhybrid" -> Ok LaserHybrid
        | "MigRobot"
        | "migrobot" -> Ok MigRobot
        | "MigManual"
        | "migmanual" -> Ok MigManual
        | "Laser"
        | "laser" -> Ok Laser
        | "TIG"
        | "tig" -> Ok TIG
        | "ArcStud"
        | "arcstud" -> Ok ArcStud
        | _ ->
            // all other cases
            let msg =
                sprintf "%s: Must be one of 'LaserHybrid', 'MigRobot', 'MigManual', 'Laser', 'TIG', 'ArcStud'" fieldName

            Error msg

type WeldType =
    | Fillet
    | Butt
    | Intermittent
    | Lap
    | Other

module WeldType =
    let value status =
        match status with
        | Fillet -> "Fillet"
        | Butt -> "Butt"
        | Intermittent -> "Intermittent"
        | Lap -> "Lap"
        | Other -> "Other"

    let create fieldName str =
        match str with
        | "Fillet"
        | "fillet" -> Ok Fillet
        | "Butt"
        | "butt" -> Ok Butt
        | "Intermittent"
        | "intermittent" -> Ok Intermittent
        | "Lap"
        | "lap" -> Ok Lap
        | "Other"
        | "other" -> Ok Other
        | _ ->
            // all other cases
            let msg =
                sprintf "%s: Must be one of 'Fillet', 'Butt', 'Intermittent', 'Lap', 'Other'" fieldName

            Error msg

type PenetrationType =
    | Fullpen
    | Partpen
    | NA

module PenetrationType =
    let value status =
        match status with
        | Fullpen -> "Fullpen"
        | Partpen -> "Partpen"
        | NA -> "NA"

    let create fieldName strOpt =
        match strOpt with
        | None -> Ok NA
        | Some str ->
            match str with
            | "Fullpen"
            | "fullpen" -> Ok Fullpen
            | "Partpen"
            | "partpen" -> Ok Partpen
            | "NA"
            | "na" -> Ok NA
            | _ ->
                // all other cases
                let msg = sprintf "%s: Must be one of 'Fullpen','Partpen','NA'" fieldName
                Error msg

type Weld =
    { Id: int
      ProjectNr: int
      WeldNr: WeldName
      Method: WeldMethod
      Type: WeldType
      ThicknessP1: float
      ThicknessP2: float
      Direction: Direction
      ThroatThickness: float
      PenetrationType: PenetrationType
      Penetration: float
      IsBeveled: bool
      BevelDescription: String250 option
      WeldDescription: String250 option
      Info: String250 option
      WPS: String50 option
      WIC: int
      MTPT: float
      VT: float }

type Msg =
    | Init
    | UpdateProjNumber of string
    | LoadWelds
    | WeldsFetched of Result<Weld list, string>
    | OnError of string
    | Submit
    | SetEditWeldNr of int
    | OnWeldCreated of Result<Weld, string>
    | OnWeldUpdated of Result<Weld, string>
    | SetWeldNr of string
    | SetMethod of string
    | SetType of string
    | SetThicknessP1 of string
    | SetThicknessP2 of string
    | SetDirection of string
    | SetThroatThicknes of string
    | SetPenetrationType of string
    | SetPenetration of string
    | SetIsBeveled of string
    | SetBevelDescription of string
    | SetWeldDescription of string
    | SetInfo of string
    | SetWPS of string
    | SetWIC of string
    | SetMTPT of string
    | SetVT of string
