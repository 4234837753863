module Global

type Page =
    | Home
    | Sveisetrad
    | Lasersveis
    | Laserlog
    | GantryCell
    | GantryCell2
    | MIGMaerskCell
    | IGMCell
    | ManualCell
    | FlyttbarCell
    | WeldTable
    | NDT
    | OrdreProjects
    | OrdreCustomers
    | OrdreProjectsIndustri
    | OrdreCustomersIndustri

let toHash page =
    match page with
    | Sveisetrad -> "#sveisetrad"
    | Home -> "#home"
    | Lasersveis -> "#lasersveis"
    | Laserlog -> "#laserlog"
    | GantryCell -> "#gantrycell"
    | GantryCell2 -> "#gantrycellto"
    | MIGMaerskCell -> "#migmaerskcell"
    | IGMCell -> "#igmcell"
    | ManualCell -> "#manualcell"
    | FlyttbarCell -> "#flyttbarcell"
    | WeldTable -> "#weldtable"
    | NDT -> "#ndt"
    | OrdreProjects -> "#ordreproject"
    | OrdreCustomers -> "#ordrecustomer"
    | OrdreProjectsIndustri -> "#ordreprojectindustri"
    | OrdreCustomersIndustri -> "#ordrecustomerindustri"
