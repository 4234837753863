module Sveisetrad.State

open Helpers
open Elmish
open Types
open System
open Thoth.Json
open Fable.SimpleHttp
open Browser

let init () : Model * Cmd<Msg> =
    { FromDate = DateTime.Now.AddDays(-20.)
      ToDate = DateTime.Now
      TempData = []
      HumData = [] },
    Cmd.none

let getTempData (fromDate: DateTime, toDate: DateTime) =
    async {
        let url = 
            sprintf 
                "api/sveisetrad/temp/from/%s/to/%s"
                (printDate fromDate)
                (printDate toDate)

        let! response = Http.request url |> Http.method GET |> Http.send

        let result =
            response.responseText
            |> Decode.fromString (Decode.list Temp.Decoder)

        match result with
        | Ok data -> return OnTempDataSuccess data
        | Error e -> return OnTempErr e

    }
    |> Cmd.OfAsync.result

let getHumData (fromDate: DateTime, toDate: DateTime) =
    async {
        let url = 
            sprintf 
                "api/sveisetrad/hum/from/%s/to/%s"
                (printDate fromDate)
                (printDate toDate)


        let! response = Http.request url |> Http.method GET |> Http.send

        let result =
            response.responseText
            |> Decode.fromString (Decode.list Hum.Decoder)

        match result with
        | Ok data -> return OnHumDataSuccess data
        | Error e -> return OnHumErr e

    }
    |> Cmd.OfAsync.result

let update msg model =
    match msg with
    | Initialize -> model, getTempData (model.FromDate, model.ToDate)
    | DateRangeChanged -> model, getTempData (model.FromDate, model.ToDate)
    | OnTempDataSuccess data -> { model with TempData = data }, getHumData (model.FromDate, model.ToDate)
    | OnHumDataSuccess data -> { model with HumData = data }, Cmd.none
    | OnTempErr _ -> model, Cmd.none
    | OnHumErr _ -> model, Cmd.none
    | SetChartData data ->
        match data with
        | Some (t, h) -> { model with TempData = t; HumData = h }, Cmd.none
        | None -> model, Cmd.none
    | FromDateChanged s ->
        let res, date = DateTime.TryParse s

        if res then
            { model with FromDate = date }, DateRangeChanged |> Cmd.ofMsg
        else
            model, Cmd.none
    | ToDateChanged s ->
        let res, date = DateTime.TryParse s

        if res then
            { model with ToDate = date }, DateRangeChanged |> Cmd.ofMsg
        else
            model, Cmd.none
