module OrdreProjects.State

open System
open Helpers
open Validators
open OrdreProjects.Types
open OrdreProjects.API
open Elmish
open FsToolkit.ErrorHandling
open Feliz
open FsToolkit.ErrorHandling.Operator.Result
open Fable.Core.JS
open System

let init () =
    { Projects = []
      NewProject = OrdreProject.New()
      NextProjectNumber = ""
      Errors = ""
      Customers = [] },
    Cmd.none

let getProjects prodtex = Cmd.OfAsync.perform (API.getProjectList prodtex) () ProjectsFetched

let createProject prodtex p =
    Cmd.OfAsync.perform (API.createProject prodtex) p OnProjCreated

let getCustomers prodtex= Cmd.OfAsync.perform (API.getCustomerList prodtex) () CustomersFetched

let validateProject p =
    validation {
        let! bygg = p.Bygg |> isNullOrWhiteSpace "bygg"
        let! beskrivelse = p.Beskrivelse |> isNullOrWhiteSpace "beskrivelse"
        let! kunde = p.Kunde |> isNoneNullOrWhiteSpace "kunde"
        let! kundeNr = p.KundeNr |> isNoneZeroOrNegative "kundeNr"
        let! ansvarlig = p.Ansvarlig |> isNoneNullOrWhiteSpace "ansvarlig"
        let! ordreDato = p.OrdreDato |> optionToResult "OrdreDato må være satt"
        return {
            p with
                Bygg = bygg;
                Beskrivelse = beskrivelse;
                Kunde = Some kunde;
                KundeNr = Some kundeNr;
                Ansvarlig = Some ansvarlig;
                OrdreDato = Some ordreDato;
                Leveringsdato = Some ordreDato }
    }

let findNextProjNumber projects =
    projects
    |> List.map (fun p -> p.Bygg)
    |> List.choose (fun p -> Helpers.parseInt p  )
    |> List.maxBy id
    |> (+) 1
    |> string

let update (prodtex: Prodtex) (msg: Msg) (model: Model) =
    match msg with
    | Init -> model, Cmd.batch [ getProjects prodtex; getCustomers prodtex ]
    | OnError e -> { model with Errors = e }, Cmd.none

    | ProjectsFetched data ->
        match data with
        | Ok projects ->
            { model with
                Projects = projects;
                NewProject =
                    { model.NewProject with
                        Bygg = findNextProjNumber projects } }
            , Cmd.none
        | Error e -> { model with Errors = e }, Cmd.none

    | CustomersFetched data ->
        match data with
        | Ok customers -> { model with Customers = customers }, Cmd.none
        | Error e -> { model with Errors = e }, Cmd.none

    | LoadCusts -> model, getCustomers prodtex
    | LoadProjects -> model, getProjects prodtex
    | OnProjCreated data ->
        match data with
        | Ok p ->
            { model with
                Projects = (p :: model.Projects) },
            Cmd.none
        | Error e -> { model with Errors = e }, Cmd.none
    | UpdateNewProject transform ->
        {model with NewProject = transform model.NewProject}
        , Cmd.none
    | Submit ->
        match validateProject model.NewProject with
        | Ok p ->
            { model with NewProject = p },
            createProject prodtex p
        | Error e ->
            { model with Errors = e |> String.concat "\n"}
            , Cmd.none
