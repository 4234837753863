module Lasersveis.Types

open System
open Thoth.Json
open Thoth.Elmish

// All cases of manual classification
type Classification =
    | OK
    | NOK
    | NE

// Convert from bool option to classification
module Classification =
    let create (b: bool option) =
        match b with
        | Some b -> if b then OK else NOK
        | None -> NE

// Lasersveis DTO
type Lasersveis =
    { Id: int
      DateWelded: DateTime
      LaserPower: int
      Duration: int
      LightPath: int
      LaserProgram: int
      PlasmoOk: bool
      PlasmoProgram: int
      ManualClassification: bool option
      ManualClassificationDescription: string option }
    // Json Deserialization
    static member Decoder =
        Decode.object
            (fun get ->
                { Id = get.Required.Field "Id" Decode.int
                  DateWelded = get.Required.Field "DateWelded" DecodeSpecial.datetime
                  LaserPower = get.Required.Field "LaserPower" Decode.int
                  Duration = get.Required.Field "Duration" Decode.int
                  LightPath = get.Required.Field "LightPath" Decode.int
                  LaserProgram = get.Required.Field "LaserProgram" Decode.int
                  PlasmoOk = get.Required.Field "PlasmoOk" Decode.bool
                  PlasmoProgram = get.Required.Field "PlasmoProgram" Decode.int
                  ManualClassification = get.Optional.Field "ManualClassification" Decode.bool
                  ManualClassificationDescription = get.Optional.Field "ManualClassificationDescription" Decode.string })
    // Helper method for getting classification
    static member Classification ls =
        ls.ManualClassification |> Classification.create

// Manual classification DTO
type ManualClassificationResponse =
    { Id: int
      ManualClassification: bool option
      ManualClassificationDescription: string option }
    // Json Deserialization
    static member Decoder =
        Decode.object
            (fun get ->
                { Id = get.Required.Field "Id" Decode.int
                  ManualClassification = get.Optional.Field "ManualClassification" Decode.bool
                  ManualClassificationDescription = get.Optional.Field "ManualClassificationDescription" Decode.string })

type SearchMode =
    | Top20
    | DateRange

// Lasersveis page model
type Model =
    { FromDate: DateTime
      ToDate: DateTime
      ErrorMsg: string
      Lasersveis: Lasersveis list
      UnchangedLasersveis: Lasersveis list
      SearchMode: SearchMode }

type Msg =
    // Initialize/Refresh data
    | Initialize
    | Refresh
    | OnRefreshed of Lasersveis list
    | OnListRefreshedError of string
    | OnError of string

    // Input data from user
    | SearchModeChanged of SearchMode
    | ToDateChanged of string
    | FromDateChanged of string
    | OnClassificationChanged of int * Classification
    | OnClassificaitonDescriptionChanged of int * string

    // Update a robot weld with new classification data
    | ClassificationUpdate of int
    | ClassificationUpdateSucceded of ManualClassificationResponse
    | ClassificationUpdateError of string
