module OrdreCustomers.API

open System
open OrdreCustomers.Types
open Thoth.Json
open Fable.SimpleHttp

let private createUrl cell path = 
    let basepath = 
        match cell with
        | AS -> "api/ordre/"
        | Industri -> "api/ordreIndustri/"
    basepath + path

let getCustomerList prodtex () =
    async {
        let url = createUrl prodtex "customers"
        let! response = Http.request url |> Http.method GET |> Http.send

        match response.statusCode with
        | 200 -> return response.responseText |> Decode.fromString (Decode.list OrdreCustomer.Decoder)
        | _ -> return Error "Server error getting customer list"
    }

let createCustomer prodtex (c: OrdreCustomer) =
    async {
        let data = c |> OrdreCustomer.Encode
        let url = createUrl prodtex "customers"

        let! response =
            Http.request url
            |> Http.method POST
            |> Http.content (BodyContent.Text data)
            |> Http.send

        match response.statusCode with
        | 200 -> return response.responseText |> Decode.fromString OrdreCustomer.Decoder
        | _ -> return Error response.responseText
    }
