module OrdreCustomers.View

open Helpers
open Elmish
open System
open Fable.React
open Feliz
open Feliz.Bulma
open OrdreCustomers.Types
open OrdreCustomers.State
open Common
open Elements
open Fable.Import.RemoteDev

let optStrInput s =
    if String.IsNullOrEmpty s then
        None
    else
        Some s

let newCustomerForm model dispatch =
    Html.div [
        prop.className "column is-half"
        prop.children [
            Html.form [
                Bulma.field.div [
                  Bulma.label "Navn"
                  Bulma.control.div [
                    Bulma.input.text [
                      prop.defaultValue (printStrOpt model.NewCustomer.Kundenavn)
                      prop.onTextChange (fun s -> dispatch (UpdateNewCustomer (fun c -> {c with Kundenavn = optStrInput s})))
                    ]
                  ]
                ]
                Bulma.field.div [
                  Bulma.label "Kontaktperson"
                  Bulma.control.div [
                    Bulma.input.text [
                      prop.defaultValue (printStrOpt model.NewCustomer.Kontaktperson)
                      prop.onTextChange (fun s -> dispatch (UpdateNewCustomer (fun c -> {c with Kontaktperson = optStrInput s})))
                    ]
                  ]
                ]
                Bulma.field.div [
                  Bulma.label "Telefon"
                  Bulma.control.div [
                    Bulma.input.tel [
                      prop.defaultValue (printStrOpt model.NewCustomer.Telefon)
                      prop.onTextChange (fun s -> dispatch (UpdateNewCustomer (fun c -> {c with Telefon = optStrInput s})))
                    ]
                  ]
                ]
                Bulma.field.div [
                  Bulma.label "EMail"
                  Bulma.control.div [
                    Bulma.input.email [
                      prop.defaultValue (printStrOpt model.NewCustomer.EMail)
                      prop.onTextChange (fun s -> dispatch (UpdateNewCustomer (fun c -> {c with EMail = optStrInput s})))
                    ]
                  ]
                ]
                Bulma.field.div [
                  Bulma.label "Gate1"
                  Bulma.control.div [
                    Bulma.input.text [
                      prop.defaultValue (printStrOpt model.NewCustomer.Adresse1)
                      prop.onTextChange (fun s -> dispatch (UpdateNewCustomer (fun c -> {c with Adresse1 = optStrInput s})))
                    ]
                  ]
                ]
                Bulma.field.div [
                  Bulma.label "Gate2"
                  Bulma.control.div [
                    Bulma.input.text [
                      prop.defaultValue (printStrOpt model.NewCustomer.Adresse2)
                      prop.onTextChange (fun s -> dispatch (UpdateNewCustomer (fun c -> {c with Adresse2 = optStrInput s})))
                    ]
                  ]
                ]
                Bulma.field.div [
                  Bulma.label "Postnr"
                  Bulma.control.div [
                    Bulma.input.text [
                      prop.defaultValue (printStrOpt model.NewCustomer.Zip)
                      prop.onTextChange (fun s -> dispatch (UpdateNewCustomer (fun c -> {c with Zip = optStrInput s})))
                    ]
                  ]
                ]
                Bulma.field.div [
                  Bulma.label "Stad"
                  Bulma.control.div [
                    Bulma.input.text [
                      prop.defaultValue (printStrOpt model.NewCustomer.Adresse3)
                      prop.onTextChange (fun s -> dispatch (UpdateNewCustomer (fun c -> {c with Adresse3 = optStrInput s})))
                    ]
                  ]
                ]
                Bulma.field.div [
                  Bulma.label "Land"
                  Bulma.control.div [
                    Bulma.input.text [
                      prop.defaultValue (printStrOpt model.NewCustomer.Adresse4)
                      prop.onTextChange (fun s -> dispatch (UpdateNewCustomer (fun c -> {c with Adresse4 = optStrInput s})))
                    ]
                  ]
                ]
                Bulma.field.div [
                  Bulma.field.isGrouped
                  prop.children [
                    Bulma.control.div [
                      Bulma.button.button [
                        Bulma.color.isLink
                        prop.text "Submit"
                        prop.onClick (fun _ -> dispatch Submit)
                      ]
                    ]
                  ]
                ]
            ]
        ]
    ]

let renderCustomerRow (customer: OrdreCustomer) =
    Html.tr
        [ prop.key customer.KundeNr
          prop.children
              [ Html.td customer.KundeNr
                Html.td (printStrOpt customer.Kundenavn)
                Html.td (printStrOpt customer.Kontaktperson)
                Html.td (printStrOpt customer.Telefon)
                Html.td (printStrOpt customer.EMail)
                Html.td (printStrOpt customer.Adresse1)
                Html.td (printStrOpt customer.Adresse2)
                Html.td (printStrOpt customer.Zip)
                Html.td (printStrOpt customer.Adresse3)
                Html.td (printStrOpt customer.Adresse4)
                 ] ]

let renderCustomerTable model dispatch =
    Bulma.tableContainer
        [ prop.style [ style.overflow.auto ]
          prop.children
              [ Bulma.table
                    [ table.isBordered
                      table.isStriped
                      table.isHoverable
                      prop.children
                          [ Html.thead
                                [ Html.tr
                                      [ Html.td [ str "KunderNr" ]
                                        Html.td [ str "Navn" ]
                                        Html.td [ str "KontaktPerson" ]
                                        Html.td [ str "Telefon" ]
                                        Html.td [ str "Epost" ]
                                        Html.td [ str "Adresse 1" ]
                                        Html.td [ str "Adresse 2" ]
                                        Html.td [ str "Postnr" ]
                                        Html.td [ str "Stad" ]
                                        Html.td [ str "Land" ] ]]
                            Html.tableBody
                                [ for customer in model.Customers do
                                      renderCustomerRow customer ] ] ] ] ]


let root (model: Types.Model) (dispatch: Types.Msg -> Unit) =
    Html.div [ 
      Html.h1 [str model.Errors]
      newCustomerForm model dispatch
      Html.br []
      renderCustomerTable model dispatch ]
