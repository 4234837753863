module WeldingTable.API

open Types
open FsToolkit.ErrorHandling
open Common
open Fable.SimpleHttp
open Fable.RemoteData
open Thoth.Json
open Helpers

type WeldDto =
    { Id: int
      ProjectNr: int
      WeldNr: string
      Method: string
      Type: string
      ThicknessP1: float
      ThicknessP2: float
      Direction: string
      ThroatThickness: float
      PenetrationType: string option
      Penetration: float
      IsBeveled: bool
      BevelDescription: string option
      WeldDescription: string option
      Info: string option
      WPS: string option
      WIC: int
      MTPT: float
      VT: float }

    static member Decoder =
        Decode.object (fun get ->
            { Id = get.Required.Field "Id" Decode.int
              ProjectNr = get.Required.Field "ProjectNr" Decode.int
              WeldNr = get.Required.Field "WeldNr" Decode.string
              Method = get.Required.Field "Method" Decode.string
              Type = get.Required.Field "Type" Decode.string
              ThicknessP1 = get.Required.Field "ThicknessP1" Decode.float
              ThicknessP2 = get.Required.Field "ThicknessP2" Decode.float
              Direction = get.Required.Field "Direction" Decode.string
              ThroatThickness = get.Required.Field "ThroatThickness" Decode.float
              PenetrationType = get.Optional.Field "PenetrationType" Decode.string
              Penetration = get.Required.Field "Penetration" Decode.float
              IsBeveled = get.Required.Field "IsBeveled" Decode.bool
              BevelDescription = get.Optional.Field "BevelDescription" Decode.string
              WeldDescription = get.Optional.Field "WeldDescription" Decode.string
              Info = get.Optional.Field "Info" Decode.string
              WPS = get.Optional.Field "Wps" Decode.string
              WIC = get.Required.Field "Wic" Decode.int
              MTPT = get.Required.Field "Mtpt" Decode.float
              VT = get.Required.Field "Vt" Decode.float })

    static member Encode(p: WeldDto) = Encode.Auto.toString (0, p)


module WeldDto =
    let toWeld (dto: WeldDto) : Result<Weld, string> =
        result {
            let! weldNr = WeldName.create "WeldNr" dto.WeldNr
            let! method = WeldMethod.create "Method" dto.Method
            let! weldType = WeldType.create "Type" dto.Type
            let! direction = Direction.create "Direction" dto.Direction
            let! penetrationType = PenetrationType.create "PenetrationType" dto.PenetrationType
            let! bevelDescription = String250.createOption "BevelDescription" dto.BevelDescription.Value
            let! weldDescription = String250.createOption "WeldDescription" dto.WeldDescription.Value
            let! info = String250.createOption "Info" dto.Info.Value

            let! wps = String50.createOption "WPS"  dto.WPS.Value

            let weld: Weld =
                { Id = dto.Id
                  ProjectNr = dto.ProjectNr
                  WeldNr = weldNr
                  Method = method
                  Type = weldType
                  ThicknessP1 = dto.ThicknessP1
                  ThicknessP2 = dto.ThicknessP2
                  Direction = direction
                  ThroatThickness = dto.ThroatThickness
                  PenetrationType = penetrationType
                  Penetration = dto.Penetration
                  IsBeveled = dto.IsBeveled
                  BevelDescription = bevelDescription
                  WeldDescription = weldDescription
                  Info = info
                  WPS = wps
                  WIC = dto.WIC
                  MTPT = dto.MTPT
                  VT = dto.VT }

            return weld
        }

    let fromWeld (weld: Weld) : WeldDto =
        { Id = weld.Id
          ProjectNr = weld.ProjectNr
          WeldNr = weld.WeldNr |> WeldName.value
          Method = weld.Method |> WeldMethod.value
          Type = weld.Type |> WeldType.value
          ThicknessP1 = weld.ThicknessP1
          ThicknessP2 = weld.ThicknessP2
          Direction = weld.Direction |> Direction.value
          ThroatThickness = weld.ThroatThickness
          PenetrationType = weld.PenetrationType |> PenetrationType.value |> Some
          Penetration = weld.Penetration
          IsBeveled = weld.IsBeveled
          BevelDescription = weld.BevelDescription |> String250.optValue |> Some
          WeldDescription = weld.WeldDescription |> String250.optValue |> Some
          Info = weld.Info |> String250.optValue |> Some
          WPS = weld.WPS |> String50.optValue |> Some
          WIC = weld.WIC
          MTPT = weld.MTPT
          VT = weld.VT }

    let createBlank =
        { Id = 0
          ProjectNr = 20004
          WeldNr = ""
          Method = ""
          Type = ""
          ThicknessP1 = 0.0
          ThicknessP2 = 0.0
          Direction = ""
          ThroatThickness = 0.0
          PenetrationType = None
          Penetration = 0.0
          IsBeveled = false
          BevelDescription = None
          WeldDescription = None
          Info = None
          WPS = None
          WIC = 0
          MTPT = 0.0
          VT = 0.0 }

let createWeld (w: Weld) =
    async {
        let weldJson = w |> WeldDto.fromWeld |> WeldDto.Encode

        let url = @"api/weld"

        let! response =
            Http.request url
            |> Http.method POST
            |> Http.content (BodyContent.Text weldJson)
            |> Http.send

        match response.statusCode with
        | 200 ->
            return
                response.responseText
                |> Decode.fromString WeldDto.Decoder
                |> Result.bind WeldDto.toWeld
        | _ -> return Error response.responseText
    }

let convertDtosToWelds listOfDtos =
    match listOfDtos with
    | Error e -> Error e
    | Ok list -> list |> List.map WeldDto.toWeld |> List.sequenceResultM

let getAllWelds projectNr =
    async {
        let url = sprintf @"api/welds/project/%i" projectNr

        let! statusCode, responseText = Http.get url

        match statusCode with
        | 200 ->
            return
                responseText
                |> Decode.fromString (Decode.list WeldDto.Decoder)
                |> convertDtosToWelds
        | _ -> return Error responseText
    }

let getWelds (ids: int list) =
    async {
        let url = @"api/welds"

        let idsJson = Encode.Auto.toString (0, ids)

        let! response =
            Http.request url
            |> Http.method POST
            |> Http.content (BodyContent.Text idsJson)
            |> Http.header (Headers.contentType "application/json")
            |> Http.send

        match response.statusCode with
        | 200 ->
            return
                response.responseText
                |> Decode.fromString (Decode.list WeldDto.Decoder)
                |> convertDtosToWelds
        | _ -> return Error response.responseText
    }

let getWeld id =
    async {
        let url = sprintf @"api/weld/%i" id

        let! response = Http.request url |> Http.method GET |> Http.send

        match response.statusCode with
        | 200 ->
            return
                response.responseText
                |> Decode.fromString WeldDto.Decoder //(Decode.map WeldDto.toWeld WeldDto.Decoder)
                |> Result.bind WeldDto.toWeld
        | _ -> return Error response.responseText
    }

let updateWeld (w: Weld) =
    async {
        let weldJson = w |> WeldDto.fromWeld |> WeldDto.Encode

        let url = @"api/weld"

        let! response =
            Http.request url
            |> Http.method PUT
            |> Http.content (BodyContent.Text weldJson)
            |> Http.send

        match response.statusCode with
        | 200 ->
            return
                response.responseText
                |> Decode.fromString WeldDto.Decoder
                |> Result.bind WeldDto.toWeld
        | _ -> return Error response.responseText
    }

let deleteWeld id =
    async {
        let url = sprintf @"api/weld/%i" id

        let! response = Http.request url |> Http.method DELETE |> Http.send

        match response.statusCode with
        | 200 -> return Ok()
        | _ -> return Error response.responseText
    }
