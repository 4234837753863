module WeldingTable.State

open Helpers
open WeldingTable.Types
open WeldingTable.API
open Elmish

type Model =

    { ProjectNr: int
      Welds: Weld list
      StatusMsg: string
      Errors: string list
      NewWeld: WeldDto
      EditWeldId: int }



let init () =
    { ProjectNr = 20004
      Welds = []
      StatusMsg = ""
      Errors = []
      EditWeldId = 0
      NewWeld = WeldDto.createBlank
    //   Debouncer = Debouncer.create()
    },
    Cmd.none

let getWelds prjectNr =
    Cmd.OfAsync.perform API.getAllWelds prjectNr WeldsFetched

let createWeld (w: Weld) =
    Cmd.OfAsync.perform API.createWeld w OnWeldCreated

let updateWeld (w: Weld) =
    Cmd.OfAsync.perform API.updateWeld w OnWeldUpdated

let findWeldById id model =
    model.Welds |> List.tryFind (fun w -> w.Id = id)

let private updateNewWeld transform (model: Model) =
    { model with NewWeld = transform <| model.NewWeld }, Cmd.none

let update (msg: Msg) (model: Model) =
    match msg with
    | Init -> model, getWelds model.ProjectNr
    | OnError e -> { model with StatusMsg = e }, Cmd.none
    | UpdateProjNumber str ->
        match str |> parseInt with
        | Some i ->
            { model with
                NewWeld = { model.NewWeld with ProjectNr = i }
                ProjectNr = i },
            Cmd.none
        | None -> model, Cmd.none
    | LoadWelds -> model, getWelds model.ProjectNr
    | WeldsFetched data ->
        match data with
        | Ok welds -> { model with Welds = welds }, Cmd.none
        | Error e -> { model with StatusMsg = e }, Cmd.none
    | Submit ->
        let weld = model.NewWeld |> WeldDto.toWeld

        match weld with
        | Ok w ->
            if model.EditWeldId = 0 then
                model, createWeld w
            else
                model, updateWeld w
        | Error msg -> { model with StatusMsg = msg }, Cmd.none
    | OnWeldCreated data ->
        match data with
        | Ok weld ->
            { model with
                NewWeld = WeldDto.createBlank
                EditWeldId = 0
                Welds = weld :: model.Welds |> List.sortBy (fun w -> w.Id) },
            Cmd.none
        | Error e -> { model with StatusMsg = e }, Cmd.none
    | OnWeldUpdated data ->
        match data with
        | Ok weld ->
            { model with
                EditWeldId = 0
                NewWeld = WeldDto.createBlank
                Welds = model.Welds |> List.map (fun w -> if w.Id = weld.Id then weld else w) },
            Cmd.none
        | Error e -> { model with StatusMsg = e }, Cmd.none
    | SetEditWeldNr i ->
        match i with
        | 0 ->
            { model with
                EditWeldId = 0
                NewWeld = WeldDto.createBlank },
            Cmd.none
        | x ->
            let weldOpt = findWeldById x model

            let weld =
                weldOpt
                |> Option.map WeldDto.fromWeld
                |> Option.defaultValue WeldDto.createBlank

            { model with
                EditWeldId = x
                NewWeld = weld },
            Cmd.none

    | SetWeldNr s -> updateNewWeld (fun w -> { w with WeldNr = s }) model
    | SetMethod s -> updateNewWeld (fun w -> { w with Method = s }) model
    | SetType s -> updateNewWeld (fun w -> { w with Type = s }) model
    | SetThicknessP1 s ->
        match s |> parseFloat with
        | Some f -> updateNewWeld (fun w -> { w with ThicknessP1 = f }) model
        | None -> model, Cmd.none
    | SetThicknessP2 s ->
        match s |> parseFloat with
        | Some f -> updateNewWeld (fun w -> { w with ThicknessP2 = f }) model
        | None -> model, Cmd.none
    | SetDirection s -> updateNewWeld (fun w -> { w with Direction = s }) model
    | SetThroatThicknes s ->
        match s |> parseFloat with
        | Some f -> updateNewWeld (fun w -> { w with ThroatThickness = f }) model
        | None -> model, Cmd.none
    | SetPenetrationType s -> updateNewWeld (fun w -> { w with PenetrationType = s |> strToOpt }) model
    | SetPenetration s ->
        match s |> parseFloat with
        | Some f -> updateNewWeld (fun w -> { w with Penetration = f }) model
        | None -> model, Cmd.none
    | SetIsBeveled str ->
        match str |> YesNo.create "IsBeveled" with
        | Ok x -> updateNewWeld (fun w -> { w with IsBeveled = x |> YesNo.value }) model
        | Error _ -> model, Cmd.none
    | SetBevelDescription s -> updateNewWeld (fun w -> { w with BevelDescription = s |> strToOpt }) model
    | SetWeldDescription s -> updateNewWeld (fun w -> { w with WeldDescription = s |> strToOpt }) model
    | SetInfo s -> updateNewWeld (fun w -> { w with Info = s |> strToOpt }) model
    | SetWPS s -> updateNewWeld (fun w -> { w with WPS = s |> strToOpt }) model
    | SetWIC s ->
        match s |> parseInt with
        | Some i -> updateNewWeld (fun w -> { w with WIC = i }) model
        | None -> model, Cmd.none
    | SetMTPT s -> updateNewWeld (fun w -> { w with MTPT = s |> percentageToFloat }) model
    | SetVT s -> updateNewWeld (fun w -> { w with VT = s |> percentageToFloat }) model

// | DebouncerSelfMsg debouncerMsg ->
//     let (debouncerModel, debouncerCmd) = Debouncer.update debouncerMsg model.Debouncer
//     { model with Debouncer = debouncerModel }, debouncerCmd
