module NDT.State

open Elmish
open Types
open System
open Thoth.Json
open Thoth.Elmish
open Fable.SimpleHttp
open FsToolkit.ErrorHandling
open FSharp.Control


let init () : Model * Cmd<Msg> =
    { ShowMode = Todo
      EditMode = false
      SelectedPOS = [||]
      SelectedWelds = []
      SelectedNDT = None
      NDTProducts = []
      StatusMsg = "" },
    Cmd.none

let APICall apiCmd onSuccess onError =
    async {
      let! result = apiCmd
      match result with
      | Ok data -> return onSuccess data
      | Error msg -> return onError msg
    } |> Cmd.OfAsync.result

let getNDTTodoList =
    APICall (API.getNDTTodoList) OnRefreshed OnError

let getNDTCompletedList =
    APICall (API.getNDTCompletedList) OnRefreshed OnError

let getProducts (ids:int list) = 
    async {
        let results = 
            List.map
                (fun id -> 
                    ProductionOrder.API.getProductionOrder id) 
                ids

        let! res = results |> Async.Parallel
        return OnPOLoaded (
            res 
            |> Array.choose 
                (function Ok d -> Some d | _ -> None))
    } |> Cmd.OfAsync.result

let getWelds (ids:int list)= 
    async{
        let! result = WeldingTable.API.getWelds ids
        match result with
        | Ok welds -> return OnWeldsLoaded welds
        | Error msg -> return OnError msg
    } |> Cmd.OfAsync.result

let update msg model =
    match msg with
    | Init -> 
        model,
            match model.ShowMode with
            | Todo -> getNDTTodoList
            | Completed -> getNDTCompletedList 
    | Refresh -> model, Cmd.none
    | OnRefreshed l -> 
        {model with NDTProducts = l}, Cmd.none
    | OnPOLoaded pos ->
        {model with SelectedPOS = pos}, Cmd.none
    | OnWeldsLoaded welds ->
        {model with SelectedWelds = welds}, Cmd.none
    | OnError s ->
        {model with StatusMsg = s}, Cmd.none
    | ToggleEditMode b ->
        {model with EditMode = b}, Cmd.none
    | ShowModeChanged mode ->
        match mode with
        | Completed ->
            { model with
                  SelectedNDT = None
                  SelectedPOS = [||]
                  SelectedWelds = []
                  NDTProducts = []
                  ShowMode = mode },
            getNDTCompletedList
        | Todo ->
            { model with
                  SelectedNDT = None
                  SelectedPOS = [||]
                  SelectedWelds = []
                  NDTProducts = []
                  ShowMode = mode },
            getNDTTodoList
    | ShowNDT prod ->
        match prod with
        | Some p -> 
            let weldIds = 
                p.CompletedWelds
                |> List.map (fun w -> w.WeldId)
                |> List.distinct
            let poIds = 
                p.CompletedWelds 
                |> List.map (fun w -> w.ProductionOrderId) 
                |> List.distinct

            {model with SelectedNDT = prod; SelectedPOS = [||]; SelectedWelds=[] }, Cmd.batch [ getProducts poIds; getWelds weldIds]
        | None ->
            {model with SelectedNDT = None; SelectedPOS = [||]; SelectedWelds=[]}, Cmd.none