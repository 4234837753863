module Laserlog.API

open Helpers
open System
open System.Text.RegularExpressions
open Types
open Thoth.Json
open Fable.SimpleHttp

let getTopLaserlog =
    async {
        let url =
            @"api/laserlog"

        let! response = Http.request url |> Http.method GET |> Http.send

        return
            response.responseText
            |> Decode.fromString (Decode.list Laserlog.Decoder)
    }

let getLaserlogFromTo (fromDate: DateTime, toDate: DateTime) =
    async {
        let url =
            sprintf
                @"api/laserlog/from/%s/to/%s"
                (printDate fromDate)
                (printDate toDate)

        let! response = Http.request url |> Http.method GET |> Http.send

        match response.statusCode with
        | 200 ->
            return
                (response.responseText
                 |> Decode.fromString (Decode.list Laserlog.Decoder))
        | v -> return Error(sprintf "Could not get list status code: %i" v)
    }

let tryParseInt s =
    let (result, i) = Int32.TryParse(s)

    match result with
    | true -> Some i
    | false -> None


let getLaserPowerLog (laserSveis: Laserlog) =
    let padMilliseconds (dateString: string) =
        let indexOfLastP = dateString.LastIndexOf('.')

        let msString =
            dateString.Substring(indexOfLastP + 1, dateString.Length - (indexOfLastP + 1))

        let zeroesToPad = 3 - msString.Length
        let padding = String.replicate zeroesToPad "0"

        dateString.Substring(0, indexOfLastP + 1)
        + padding
        + msString

    async {
        let fileString =
            match laserSveis.SamplingFileName with
            | Some s -> s.Replace(":", ".")
            | None ->
                laserSveis.DateTime.ToString("yyyy-MM-dd-HH.mm.ss.fff")
                |> padMilliseconds

        let url =
            sprintf @"https://prodtexshare.blob.core.windows.net/laserlog/%s.txt" fileString

        let! response = Http.request url |> Http.method GET |> Http.send

        match response.content with
        | ResponseContent.Text content ->
            return
                content.Split(';')
                |> Seq.choose tryParseInt
                |> Seq.toList
        | _ -> return List.empty
    }
