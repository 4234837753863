namespace global


// Stole the code for Thoth.Json decode datetime
// and changed it so it doesnt convert time to utc
module DecodeSpecial =
    open Thoth.Json
    let inline isString (o: JsonValue) : bool = o :? string

    let inline asString (o: JsonValue) : string = unbox o

    let datetime: Decoder<System.DateTime> =
        fun path value ->
            if isString value then
                match System.DateTime.TryParse(asString value) with
                | true, x -> x |> Ok
                | _ -> (path, BadPrimitive("a datetime", value)) |> Error
            else
                (path, BadPrimitive("a datetime", value)) |> Error


module Helpers = 
    open System
    open System.Text.RegularExpressions
    open System.Globalization

    let printDate (date:DateTime) =
        date.ToString("yyyy-MM-dd")

    let printStrOpt ostr =
        Option.defaultValue "" ostr

    let strToOpt str =
        if String.IsNullOrEmpty str then
            None
        else
            Some str

    let printDateOpt (date: DateTime option) =
        match date with
        | Some d -> printDate d
        | None -> ""

    let parseInt (str:string) = 
        let s, i = Int32.TryParse str
        if s then Some i else None
    let parseFloat (str:string) = 
        let s, i = Double.TryParse (str.Replace(',','.'))
        if s then Some i else None

    let parseDateTime (str:string) = 
        let res, date = DateTime.TryParse str
        if res then Some date else None

    let boolToYesNo =
        function
        | true -> "Yes"
        | false -> "No"
    
    let floatToPercentage =
        function
        | f when f < 1.0 && f > 0.0 -> sprintf "%.0f%%" (f*100.0)
        | f when f >= 1.0 -> "100%"
        | _ -> "0%"

    let percentageToFloat (s:string) =
        let m = Regex.Match(s , @"^(\d+)(%?)$")
        if m.Success then
            match m.Groups.[1].Value |> parseFloat with
            | Some f -> f/100.0
            | None -> 0.0
        else
            0.0

module Elements =
    open Fable.React
    open Fable.React.Props
    open Microsoft.FSharp.Reflection
    open Feliz.Bulma
    open Feliz
    open System


    let errorsList errors =
        let errorItem e = li [] [ str e ]
        ul [ ClassName "error-messages" ] (List.map errorItem errors)

    let dropDownFromUnion (unionType:Type) addEmptyCase (currValue:string) (onChange: string->Unit) = 
        let cases = FSharpType.GetUnionCases unionType
        Bulma.control.div [
            prop.value currValue
            prop.children [
                Bulma.select [
                    prop.children [
                        if addEmptyCase then
                            Html.option [
                                prop.text " "
                                prop.value ""
                                if currValue = "" then
                                    prop.selected true
                        ]
                        for case in cases do
                            Html.option [
                                prop.text case.Name
                                prop.value case.Name
                                if currValue = case.Name then
                                    prop.selected true
                            ]
                    ]
                ]
            ]
            prop.onChange onChange
        ]

    let dropDown (cases: string list) addEmptyCase (currValue:string) (onChange: string->Unit) = 
        Bulma.control.div [
            prop.value currValue
            prop.children [
                Bulma.select [
                    prop.children [
                        if addEmptyCase then
                            Html.option [
                                prop.text " "
                                prop.value ""
                                if currValue = "" then
                                    prop.selected true
                        ]
                        for case in cases do
                            Html.option [
                                prop.text case
                                prop.value case
                                // if currValue = case then
                                //     prop.selected true
                            ]
                    ]
                ]
            ]
            prop.onChange onChange
        ]

module Validators =
    open System

    let isNullOrWhiteSpace field s =
        if String.IsNullOrWhiteSpace s then
            Error (sprintf "%s cannot be empty" field)
        else
            Ok s

    
    let isNoneNullOrWhiteSpace field optS =
        match optS with
        | Some s -> 
            if String.IsNullOrWhiteSpace s then
                Error (sprintf "%s cannot be empty" field)
            else
                Ok s
        | None -> Error (sprintf "%s is required" field)
    
    let isZeroOrNegative field i =
        if i <= 0 then
            Error (sprintf "%s must be positive" field)
        else
            Ok i
    
    let isNoneZeroOrNegative field optI =
        match optI with
        | Some i -> 
            if i <= 0 then
                Error (sprintf "%s must be positive" field)
            else
                Ok i
        | None ->
            Error (sprintf "%s is required" field)

    let optionToResult err opt =
        match opt with
        | Some o -> Ok o
        | None -> Error err








            