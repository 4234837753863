module Lasersveis.API

open Helpers
open System
open Types
open Thoth.Json
open Fable.SimpleHttp

let getTop20Lasersvies =
    async {
        let url =
            @"api/lasersveis/"

        let! response = Http.request url |> Http.method GET |> Http.send

        return
            response.responseText
            |> Decode.fromString (Decode.list Lasersveis.Decoder)
    }

let getLasersviesFromTo (fromDate: DateTime, toDate: DateTime) =
    async {
        let url =
            sprintf
                @"api/lasersveis/from/%s/to/%s"
                (printDate fromDate)
                (printDate toDate)

        let! response = Http.request url |> Http.method GET |> Http.send

        match response.statusCode with
        | 200 ->
            return
                (response.responseText
                 |> Decode.fromString (Decode.list Lasersveis.Decoder))
        | v -> return Error(sprintf "Could not get list status code: %i" v)
    }

let putManualClassification id manualClassification manualClassificationDescription =
    async {
        let url =
            sprintf @"api/lasersveis/classify/id/%i" id

        let st1 =
            match manualClassification with
            | Some b -> sprintf "ManualClassification:%b" b
            | None -> "ManualClassification:null"

        let st2 =
            match manualClassificationDescription with
            | Some s -> sprintf "ManualClassificationDescription:\"%s\"" s
            | None -> "ManualClassificationDescription:null"

        let! response =
            Http.request url
            |> Http.method POST
            |> Http.content (BodyContent.Text("{" + st1 + "," + st2 + "}"))
            |> Http.send

        match response.statusCode with
        | 200 ->
            return
                response.responseText
                |> Decode.fromString ManualClassificationResponse.Decoder
        | v -> return Error(sprintf "Could not update status code: %i" v)
    }

let tryParseInt s =
    let (result, i) = Int32.TryParse(s)

    match result with
    | true -> Some i
    | false -> None

let getLaserPowerLog (laserSveis: Lasersveis) =
    async {
        let dateString =
            laserSveis.DateWelded.ToString("yyyy-MM-dd-HH.mm.ss.zzz")

        let url =
            sprintf @"https://prodtexshare.blob.core.windows.net/laserlog/%s.txt" dateString

        let! response = Http.request url |> Http.method GET |> Http.send

        match response.content with
        | ResponseContent.Blob content ->
            let! blobContent = FileReader.readBlobAsText content

            return
                blobContent.Split(';')
                |> Seq.choose tryParseInt
                |> Seq.toList
        | _ -> return List.empty
    }
