module Sveisetrad.View

open Fable.Core
open Fable.React
open Fable.React.Props
open Feliz
open Feliz.Recharts
open Feliz.Bulma
open Types
open System



let tempChart (data:Temp list) =
    Recharts.lineChart [
        lineChart.width 700
        lineChart.height 300
        lineChart.data data
        lineChart.margin(top=5, right=30)
        lineChart.children [
            Recharts.cartesianGrid [ cartesianGrid.strokeDasharray(5,5)]
            Recharts.xAxis [xAxis.dataKey (fun tl -> tl.Date.AddDays(1.).ToString("yyyy-MM-dd")) ]
            Recharts.yAxis []
            Recharts.tooltip []
            Recharts.legend []
            Recharts.line [
                line.monotone
                line.dataKey(fun tl -> tl.MaxTemp )
                line.stroke "#ff4a4a"
                line.name "MaxTemp"
            ]
            Recharts.line [
                line.monotone
                line.dataKey(fun tl -> tl.MinTemp )
                line.stroke "#4a7aff"
                line.name "MinTemp"
            ]
        ]
    ]

let humChart (data:Hum list) =
    Recharts.lineChart [
        lineChart.width 700
        lineChart.height 300
        lineChart.data data
        lineChart.margin(top=5, right=30)
        lineChart.children [
            Recharts.cartesianGrid [ cartesianGrid.strokeDasharray(5,5)]
            Recharts.xAxis [xAxis.dataKey (fun tl -> tl.Date.AddDays(1.).ToString("yyyy-MM-dd")) ]
            Recharts.yAxis []
            Recharts.tooltip []
            Recharts.legend []
            Recharts.line [
                line.monotone
                line.dataKey(fun tl -> tl.MaxHum )
                line.stroke "#ff4a4a"
                line.name "MaxHum"
            ]
            Recharts.line [
                line.monotone
                line.dataKey(fun tl -> tl.MinHum )
                line.stroke "#4a7aff"
                line.name "MinHum"
            ]
        ]
    ]

let root model dispatch =
  div []
    [
        div [ ClassName "columns"] [
            div [ClassName "columns"; Style [Padding "1.5rem"; FontWeight "700"]] [
                div [ClassName "column is-offset-one-quarter"] [
                    form [ClassName "box"] [
                        div [ClassName "Field"] [
                            label [Style [Display DisplayOptions.Block]] [str "From Date"]
                            div [ClassName "control"] [
                                Html.input [
                                    prop.className "input"
                                    prop.style [style.borderWidth 1; style.borderStyle.solid; style.borderColor "#dbdbdb" ]
                                    prop.type' "date"
                                    prop.value (model.FromDate.ToString("yyyy-MM-dd"))
                                    prop.placeholder (DateTime.Now.AddDays(-20.).ToString("dd-MM-yyyy"))
                                    prop.onChange (fun v -> dispatch (FromDateChanged v))
                                ]
                            ]
                        ]
                        div [ClassName "Field"] [
                            label [] [str "To Date"]
                            div [ClassName "control"] [
                                Html.input [
                                    prop.className "input"
                                    prop.style [style.borderWidth 1; style.borderStyle.solid; style.borderColor "#dbdbdb" ]
                                    prop.type' "date"
                                    prop.value (model.ToDate.ToString("yyyy-MM-dd"))
                                    prop.placeholder (sprintf "%s" (DateTime.Now.AddDays(-20.).ToString("dd-MM-yyyy")))
                                    prop.onChange (fun v -> dispatch (ToDateChanged v))
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
        div [ ClassName "columns"] [
            div [ClassName "column is-full"] [
                label [Style [FontWeight "700"]] [ str "Temprature:"]
                if model.TempData.IsEmpty then
                    h1 [] [str "Loading temprature data"]
                    Bulma.column[
                        Bulma.progress [
                            color.isPrimary
                            prop.max 100
                        ]
                    ]
                else
                    Recharts.responsiveContainer[
                        responsiveContainer.width (length.percent 100)
                        responsiveContainer.height 300
                        responsiveContainer.chart (tempChart model.TempData)
                    ]
            ]
        ]
        div [ClassName "columns"] [
            div [ClassName "column is-full"] [
                label [Style [FontWeight "700"]] [ str "Humidity:"]
                if model.HumData.IsEmpty then
                    h1 [] [str "Loading humidity data"]
                    Bulma.column [
                        Bulma.progress [
                            color.isPrimary
                            prop.max 100
                        ]
                    ]
                else
                    Recharts.responsiveContainer[
                        responsiveContainer.width (length.percent 100)
                        responsiveContainer.height 300
                        responsiveContainer.chart (humChart model.HumData)
                    ]
            ]
        ]
    ]
