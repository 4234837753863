module OrdreCustomers.State

open System
open Helpers
open Validators
open OrdreCustomers.Types
open OrdreCustomers.API
open Elmish
open FsToolkit.ErrorHandling
open Feliz
open FsToolkit.ErrorHandling.Operator.Result
open Fable.Core.JS
open System

let init () =
    { NewCustomer = OrdreCustomer.New()
      NextCustomerNumber = 0
      Errors = ""
      Customers = [] },
    Cmd.none

let getCustomers prodtex = Cmd.OfAsync.perform (API.getCustomerList prodtex) () CustomersFetched

let createCustomer prodtex c =
    Cmd.OfAsync.perform (API.createCustomer prodtex) c OnCustCreated

let validateCustomer (c: OrdreCustomer) =
    validation {
        let! adresse1 = c.Adresse1 |> isNoneNullOrWhiteSpace "Adresse1"
        let! zip = c.Zip |> isNoneNullOrWhiteSpace "Postnr"
        let! adresse3 = c.Adresse3 |> isNoneNullOrWhiteSpace "Stad"
        let! email = c.EMail |> isNoneNullOrWhiteSpace "Epost"
        let! kontaktperson = c.Kontaktperson |> isNoneNullOrWhiteSpace "Kontaktperson"
        let! name = c.Kundenavn |> isNoneNullOrWhiteSpace "Adresse1"
        return {
            c with
                Adresse1 = Some adresse1
                Adresse3 = Some adresse3
                EMail = Some email
                Kundenavn = Some name
                Zip = Some zip }
    }

let update prodtex (msg: Msg) (model: Model) =
    match msg with
    | Init -> model, getCustomers prodtex
    | OnError e -> { model with Errors = e }, Cmd.none
    | CustomersFetched data ->
        match data with
        | Ok customers -> 
            { model with Customers = customers }
            , Cmd.none
        | Error e -> { model with Errors = e }, Cmd.none
    | OnCustCreated data ->
        match data with
        | Ok c ->
            { model with
                Customers = (c :: model.Customers) },
            Cmd.none
        | Error e -> { model with Errors = e }, Cmd.none
    | UpdateNewCustomer transform -> 
        {model with NewCustomer = transform model.NewCustomer}
        , Cmd.none
    | Submit -> 
        match validateCustomer model.NewCustomer with
        | Ok c -> 
            { model with NewCustomer = c }, 
            createCustomer prodtex c
        | Error e -> 
            { model with Errors = e |> String.concat "\n"}
            , Cmd.none
