module Navbar.View

open UserInfo
open Fable.React
open Fable.React.Props
open Feliz
open Feliz.Bulma

let navButton classy href faClass txt =
    p
        [ ClassName "control" ]
        [ a
            [ ClassName (sprintf "button %s" classy)
              Href href ]
            [ span
                [ ClassName "icon" ]
                [ i
                    [ ClassName (sprintf "fab %s" faClass) ]
                    [ ] ]
              span
                [ ]
                [ str txt ] ] ]


let root (session: UserInfo option) =
    nav [ ClassName "navbar"; Style [CSSProp.BackgroundColor "#232440"] ] [ 
        div [ ClassName "container is-fluid" ] [ 
            div [ ClassName "navbar-brand" ] [ 
                h1 [ ClassName "navbar-item title is-4"; Style [CSSProp.Color "#f7f7f7"]] [ 
                    str "Prodtex Data Viewer" 
                ] 
            ]
            div [ ClassName "navbar-end"] [
                div [ ClassName "navbar-item" ] [
                    if session.IsNone then
                        Html.a [
                            prop.className "button is-primary is-small"
                            prop.text "Login"
                            prop.href ".auth/login/aad"
                        ]
                    else
                        Html.a [
                            prop.className "button is-primary is-small"
                            prop.text "Logout"
                            prop.href ".auth/logout"
                        ]
                ]
            ]
        ]
    ]