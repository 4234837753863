module OrdreProjects.Types

open System
open Thoth.Json

type Prodtex = AS | Industri

type OrdreCustomer =
    { Name: string option
      Number: int }
    static member Decoder =
        Decode.object (fun get ->
            { Name = get.Optional.Field "Name" Decode.string
              Number = get.Required.Field "Number" Decode.int })

    static member Encode(cust: OrdreCustomer) = Encode.Auto.toString (0, cust)

type OrdreProject =
    { Ansvarlig: string option
      Beskrivelse: string
      Bygg: string
      Fokus: bool option
      Kanselert: bool option
      Konto: string option
      Kunde: string option
      KundeNr: int option
      Kundensref: string option
      Leveringsdato: DateTime option
      Levert: bool option
      OrdreDato: DateTime option
      PId: string option
      Prosjektleiarkunde: string option
      Spec: string option
      SsmaTimeStamp: string }
    static member Decoder =
        Decode.object (fun get ->
            { Ansvarlig = get.Optional.Field "Ansvarlig" Decode.string
              Beskrivelse = get.Required.Field "Beskrivelse" Decode.string
              Bygg = get.Required.Field "Bygg" Decode.string
              Fokus = get.Optional.Field "Fokus" Decode.bool
              Kanselert = get.Optional.Field "Kanselert" Decode.bool
              Konto = get.Optional.Field "Konto" Decode.string
              Kunde = get.Optional.Field "Kunde" Decode.string
              KundeNr = get.Optional.Field "KundeNr" Decode.int
              Kundensref = get.Optional.Field "Kundensref" Decode.string
              Leveringsdato = get.Optional.Field "Leveringsdato" Decode.datetime
              Levert = get.Optional.Field "Levert" Decode.bool
              OrdreDato = get.Optional.Field "Ordredato" Decode.datetime
              PId = get.Optional.Field "PId" Decode.string
              Prosjektleiarkunde = get.Optional.Field "Prosjektleiarkunde" Decode.string
              Spec = get.Optional.Field "Spec" Decode.string
              SsmaTimeStamp = get.Required.Field "SsmaTimeStamp" Decode.string })

    static member New() =
        { Ansvarlig = None
          Beskrivelse = ""
          Bygg = "0"
          Fokus = None
          Kanselert = None
          Konto = None
          Kunde = None
          KundeNr = None
          Kundensref = None
          Leveringsdato = None
          Levert = None
          OrdreDato = Some(DateTime.Now)
          PId = None
          Prosjektleiarkunde = None
          Spec = None
          SsmaTimeStamp = "AAAAAAAA+JM=" }



    static member Encode(proj: OrdreProject) = Encode.Auto.toString (0, proj)

type Model =
    { Projects: OrdreProject list
      NewProject: OrdreProject
      NextProjectNumber: string
      Errors: string
      Customers: OrdreCustomer list }

type Msg =
    | Init
    | LoadCusts
    | LoadProjects
    | ProjectsFetched of Result<OrdreProject list, string>
    | CustomersFetched of Result<OrdreCustomer list, string>
    | OnError of string
    | Submit
    | OnProjCreated of Result<OrdreProject, string>
    | UpdateNewProject of (OrdreProject -> OrdreProject)
