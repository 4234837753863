module ProductionOrder.State

open Elmish
open Types
open System
open Thoth.Json
open Thoth.Elmish
open Fable.SimpleHttp
open FsToolkit.ErrorHandling


let init () : Model * Cmd<Msg> =
    { Products = List.empty
      DiffProducts = List.empty
      StatusMsg = ""
      ShowMode = Todo
      ShowWelds = false
      Welds = [] },
    Cmd.none


let getTodoList cell onSuccess onError =
    async {
        let! result = API.getProductTodoList (cellStr cell)

        match result with
        | Ok data -> return onSuccess (data, data)
        | Error msg -> return onError msg
    }
    |> Cmd.OfAsync.result

let getCompeltedList cell onSuccess onError =
    async {
        let! result = API.getProductCompletedList (cellStr cell)

        match result with
        | Ok data -> return onSuccess (data, data)
        | Error msg -> return onError msg
    }
    |> Cmd.OfAsync.result

let getProductWelds (product: ProductionOrder) onSuccess onError =
    async {
        let! result = API.getProductWelds product.Id

        match result with
        | Ok data -> return onSuccess data
        | Error msg -> return onError msg
    }
    |> Cmd.OfAsync.result

let replaceProductById (newPrd: ProductionOrder) (oldPrd: ProductionOrder) =
    if newPrd.Id = oldPrd.Id then newPrd else oldPrd

let updateListWithNewProduct updatedProduct products =
    products |> List.map (replaceProductById updatedProduct)

let removeProductFromList (product: ProductionOrder) (products: ProductionOrder list) =
    products |> List.choose (fun p -> if p.Id = product.Id then None else Some p)

let updateProductInDb product onSuccess onError =
    async {
        let! result = API.updateProductionOrder product

        match result with
        | Ok data -> return onSuccess data
        | Error msg -> return onError msg
    }
    |> Cmd.OfAsync.result

let update cell msg model =
    match msg with
    | Init -> model, getTodoList cell OnRefreshed OnError
    | Refresh -> model, Cmd.none
    | OnRefreshed(l, d) ->
        { model with
            Products = l
            DiffProducts = d },
        Cmd.none
    | OnError s -> { model with StatusMsg = s }, Cmd.none

    | ShowModeChanged mode ->
        match mode with
        | Completed ->
            { model with
                Products = []
                DiffProducts = []
                ShowMode = mode },
            getCompeltedList cell OnRefreshed OnError
        | Todo ->
            { model with
                Products = []
                DiffProducts = []
                ShowMode = mode },
            getTodoList cell OnRefreshed OnError

    | ShowWelds product ->
        match product with
        | Some prd -> { model with ShowWelds = true }, getProductWelds prd OnWeldsUpdated OnError
        | None ->
            { model with
                ShowWelds = false
                Welds = [] },
            Cmd.none

    | OnWeldsUpdated welds -> { model with Welds = welds }, Cmd.none

    | OnProductChanged prd -> { model with Products = model.Products |> updateListWithNewProduct prd }, Cmd.none
    | OrderUpdate p ->
        let updatedProduct =
            match p.Approved with
            | false ->
                { p with
                    Approved = true
                    ApprovedDate = Some DateTime.Now }
            | true ->
                { p with
                    Approved = false
                    ApprovedBy = None }

        model, updateProductInDb updatedProduct OrderUpdateSuccess OnError
    | OrderUpdateSuccess product ->
        let newProductList = model.Products |> removeProductFromList product

        let newDiffProductList = model.DiffProducts |> removeProductFromList product

        { model with
            Products = []
            DiffProducts = [] },
        Cmd.ofMsg (OnRefreshed(newProductList, newDiffProductList))
