module NDT.View

open Helpers
open FSharp.Reflection
open Types
open Fable.Core
open Fable.React
open Fable.React.Props
open Feliz
open Feliz.Bulma
open System
open System.Globalization



let renderProductRow (dispatch: Msg -> unit) (product: NDTProduct) =
    Html.tr [
        prop.children [
            Html.td product.PartName
            Html.td product.PartNumber
            Html.td product.CompletedWelds.Length
            Html.td [
                Bulma.button.button [
                    Bulma.button.isText
                    prop.text "Show"
                    prop.onClick (fun _ -> ShowNDT (Some product) |> dispatch)
                ]
            ]
        ]
    ]

let renderCompletedProductRow dispatch (product: NDTProduct) =
    Html.tr [
        prop.children [
            Html.td product.PartName
        ]
    ]

let renderTodoTable dispatch products =
    Html.div [
        prop.className "table-container"
        prop.children [
            Html.table [
                prop.className "table is-bordered is-fullwidth"
                prop.children [
                    Html.thead [
                        Html.tr [
                            Html.th "PartName"
                            Html.th "PartNumber"
                            Html.th "Welds"
                        ]
                    ]                
                    Html.tbody [
                        for product in products do
                            renderProductRow dispatch product
                    ]
                ]
            ]
        ]
    ]

let renderCompletedTable dispatch products =
    Html.div [
        prop.className "table-container"
        prop.children [
            Html.table [
                prop.className "table is-bordered is-fullwidth"
                prop.children [
                    Html.thead [
                        Html.tr [
                            Html.th "PartName"
                            Html.th "PartNumber"
                        ]
                    ]                
                    Html.tbody [
                        for product in products do
                            renderCompletedProductRow dispatch product
                    ]
                ]
            ]
        ]
    ]

let renderShowModeButton model dispatch =
    Html.div[
        prop.className "columns is-3 is-variable"
        prop.children[
            Html.div[
                prop.className "control"
                prop.children[
                    label [Style [Display DisplayOptions.Block]] [str "Show:"]
                    match model.ShowMode with
                    | Todo ->
                        Bulma.button.button[
                            Bulma.color.isLight
                            prop.text "Completed"
                            prop.onClick (fun _ -> ShowModeChanged Completed |> dispatch)
                        ]
                        Bulma.button.button[
                            prop.style [style.backgroundColor "#3273dc"; style.color "#fff"]
                            prop.text "Todo"
                        ]
                    | Completed ->
                        Bulma.button.button[
                            prop.style [style.backgroundColor "#3273dc"; style.color "#fff"]
                            prop.text "Completed"
                        ]
                        Bulma.button.button[
                            Bulma.color.isLight
                            prop.text "Todo"
                            prop.onClick (fun _ -> ShowModeChanged Todo |> dispatch)
                    ]
                ]
            ]
        ]
    ]

let showWeldRow (item:ProductionOrder.Types.Weld*ProductionOrder.Types.ProductionOrder option*WeldingTable.Types.Weld option) dispatch =
    let weld,po,wr = item

    let PartNumber (po:ProductionOrder.Types.ProductionOrder) = 
        po.MarkWith |> printStrOpt
    let Operator (po:ProductionOrder.Types.ProductionOrder) = 
        po.ApprovedBy |> printStrOpt
    let weldMethod (wr:WeldingTable.Types.Weld) = 
        wr.Method |> WeldingTable.Types.WeldMethod.value
    let wic (wr:WeldingTable.Types.Weld) = 
        wr.WIC.ToString()
    let mtpt (wr:WeldingTable.Types.Weld) = 
        wr.MTPT |> floatToPercentage
    let vt (wr:WeldingTable.Types.Weld) = 
        wr.VT |> floatToPercentage

    let get (f: 'a -> string) (opt:option<'a>) : string =
        match opt with
        | Some o -> o |> f
        | None -> ""

    Html.tr [
        prop.children [
            Html.td (po |> get PartNumber)
            Html.td weld.WeldName
            Html.td (wr |> get weldMethod) 
            Html.td (po |> get Operator)
            Html.td (wr |> get wic)
            Html.td (wr |> get mtpt)
            Html.td (wr |> get vt)
            Html.td weld.NDTFillerMatBatch
            Html.td weld.NDTDefects
            Html.td (Option.defaultValue "" weld.NDTComments)
            Html.td [
                    Bulma.button.button [
                        prop.onClick (fun _ -> ())
                        prop.children [
                            Bulma.icon [
                                Bulma.icon.isLarge
                                prop.children[
                                    Html.i [prop. className "fas fa-check"]
                                ]
                            ]
                        ]
                    ]
            ]
        ]
    ]

let showWeldTable model dispatch =
    let selectedNDT = model.SelectedNDT.Value
    let welds = 
        selectedNDT.CompletedWelds
        |> List.map 
            (fun w ->
                w, 
                (Array.tryFind 
                    (fun (p:ProductionOrder.Types.ProductionOrder) -> 
                        w.ProductionOrderId = p.Id) 
                    model.SelectedPOS),
                (List.tryFind
                    (fun (wr: WeldingTable.Types.Weld) ->
                        w.WeldId = wr.Id)
                    model.SelectedWelds)
            )
    Html.table [
        prop.className "table is-bordered is-fullwidth"
        prop.children [
            Html.thead [
                Html.tr [
                    Html.th "PartNumber"
                    Html.th "Name"
                    Html.th "Method"
                    Html.th "Welder"
                    Html.th "WIC"
                    Html.th "MTPT"
                    Html.th "VT"
                    Html.th "FillerMatBatch"
                    Html.th "Defects"
                    Html.th "Comments"
                ]
            ]                
            Html.tbody [
                for weld in welds do
                    showWeldRow weld dispatch
            ]
        ]
    ]

let showNDTPage model dispatch =
    Html.div [
        prop.className "table-container"
        prop.children [
            Bulma.button.button [
                button.isNormal
                prop.onClick (fun _ -> ShowNDT None |> dispatch)
                prop.children [
                    Bulma.icon [
                        icon.isLarge
                        prop.children[
                            Html.i [prop. className "fas fa-arrow-left"]
                        ]
                    ]
                    Html.span [prop.text "Go Back"]
                ]
            ]
            showWeldTable model dispatch
        ]
    ]

let showNDTTable model dispatch =
    Html.div [
        renderShowModeButton model dispatch
        Html.br []
        Html.h1 model.StatusMsg
        Html.div [
            prop.className "columns"
            prop.children [
                match model.ShowMode with
                | Todo -> 
                    renderTodoTable dispatch model.NDTProducts
                | Completed ->
                    renderCompletedTable dispatch model.NDTProducts
            ]
        ]
    ]

let root model dispatch =
    Html.div [
        Html.br []
        Html.h1 model.StatusMsg
        match model.SelectedNDT with
        | Some _ -> showNDTPage model dispatch
        | None -> showNDTTable model dispatch
    ]
