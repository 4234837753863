module ProductionOrder.API

open System
open ProductionOrder.Types
open Thoth.Json
open Fable.SimpleHttp
open Fable.Import


let getProductTodoList (cell: string) =
    async {
        let url = sprintf @"api/ProductionOrders/cell/%s" cell

        let! response = Http.request url |> Http.method GET |> Http.send

        match response.statusCode with
        | 200 -> return response.responseText |> Decode.fromString (Decode.list ProductionOrder.Decoder)
        | _ -> return Error "Server error getting todo list"
    }

let getProductCompletedList (cell: string) =
    async {
        let url = sprintf @"api/ProductionOrders/completed/cell/%s" cell

        let! response = Http.request url |> Http.method GET |> Http.send

        match response.statusCode with
        | 200 -> return response.responseText |> Decode.fromString (Decode.list ProductionOrder.Decoder)
        | _ -> return Error "Server error getting completed list"
    }

let updateProductionOrder (product: ProductionOrder) =
    async {
        let url = sprintf @"api/ProductionOrders/%i" product.Id

        let productJson = ProductionOrder.Encoder product

        let! response =
            Http.request url
            |> Http.method PUT
            |> Http.content (BodyContent.Text productJson)
            |> Http.send

        match response.statusCode with
        | 200 -> return response.responseText |> Decode.fromString ProductionOrder.Decoder
        | 404 -> return Error "Product not found"
        | _ -> return Error response.responseText
    }

let getProductionOrder id =
    async {
        let url = sprintf @"api/ProductionOrders/id/%i" id

        let! response = Http.request url |> Http.method GET |> Http.send

        match response.statusCode with
        | 200 -> return response.responseText |> Decode.fromString ProductionOrder.Decoder
        | 404 -> return Error "Product not found"
        | _ -> return Error response.responseText
    }

let getProductWelds productId =
    async {
        let url = sprintf @"api/ProductionOrders/%i/welds" productId

        let! response = Http.request url |> Http.method GET |> Http.send

        match response.statusCode with
        | 200 -> return response.responseText |> Decode.fromString (Decode.list Weld.Decoder)
        | 404 -> return Error "Not found"
        | _ -> return Error response.responseText
    }
